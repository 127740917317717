import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HorizonMode } from "../../../@types";
import Loader from "../../../components/Loader";
import { HORIZON_MODE } from "./constants";
import { useData } from "./hooks/useData";
import { useHydratedFields } from "./hooks/useHydratedFields";
import FieldsDetails from "./PickKeysForField";
import Radar from "./Radar";
import { useNavigateField } from "./Radar/utils";
import { CockpitWrapper, FieldsDetailsWrapper, RadarWrapper } from "./styled";
import { setFavIcon, tryEmbedCssLink } from "../../../utils/embed-utils";

function Cockpit() {
  const { clientId, fieldId } = useParams<any>();
  const [horizonMode, setHorizonMode] = useState(
    HORIZON_MODE.short as HorizonMode
  );
  const {
    fields,
    matrix,
    connectionLabels,
    isLoading,
    testSets,
    entries,
    entriesBySheetMap,
    testSetsBySheetMap,
    settings,
    labels,
    headerHtml,
    clientCss,
    clientFavIconUrl,
  } = useData(clientId!, horizonMode);

  const { hydratedFields, hydratedFieldsMap } = useHydratedFields(
    fields,
    matrix,
    connectionLabels,
    testSets,
    entries,
    entriesBySheetMap
  );
  const navigateField = useNavigateField();

  useEffect(() => {
    if (clientCss) {
      tryEmbedCssLink(clientCss);
    }

    if (clientFavIconUrl) {
      setFavIcon(clientFavIconUrl);
    }
  }, [clientCss, clientFavIconUrl]);

  function handleTagClicked(fieldId: string) {
    navigateField(fieldId);
  }

  if (isLoading) {
    return <Loader />;
  }

  const currentField = fieldId ? hydratedFieldsMap[fieldId] : hydratedFields[0];
  const currentEntries = entriesBySheetMap[currentField.sheetId] || [];
  const currentTestSets = testSetsBySheetMap[currentField.sheetId] || [];

  return (
    <CockpitWrapper>
      <div dangerouslySetInnerHTML={{ __html: headerHtml }}></div>
      <RadarWrapper>
        <Radar
          fields={hydratedFields as any}
          fieldsMap={hydratedFieldsMap as any}
          onTagClicked={handleTagClicked}
          mode={horizonMode}
          onModeChanged={setHorizonMode}
          currentField={currentField}
          settings={settings}
        />
      </RadarWrapper>
      <FieldsDetailsWrapper className="tg_page page aggregated-dashboard-page aggregated-report-page">
        <FieldsDetails
          hydratedFields={hydratedFields}
          currentField={currentField}
          testSets={currentTestSets}
          entries={currentEntries}
          labels={labels}
        />
      </FieldsDetailsWrapper>
    </CockpitWrapper>
  );
}

export default Cockpit;
