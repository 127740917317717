import { countBy, max, round, sum } from "lodash-es";
import React from "react";
import { VISUALIZATIONS_TYPES } from "../../constants";
import { Typography } from "../../Test/components";
import Visualization from "../../Test/sub-pages/TestResults/visualization";

function AggregatedScale({ scale, formings, averageLabel, distributionLabel }) {
  const averageForming = round(sum(formings) / formings.length, 2);
  const highestForming = max(formings);
  const formingsCount = countBy(formings);
  const formingsDistribution = Object.keys(formingsCount).map(key => {
    const count = formingsCount[key];
    const percentage = round((count / formings.length) * 100, 2);
    return {
      value: key * 1,
      count,
      percentage,
    };
  });

  return (
    <div className="scale-container">
      <Typography h1>{scale.scaleName}</Typography>
      {scale.formingVisualization === VISUALIZATIONS_TYPES.CATEGORY ? (
        <div className="visualization-wrapper">
          <div className="visualization-items">
            <div className="visualization-item">
              <Visualization scale={scale} value={highestForming} />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="visualization-wrapper">
            <Typography h2>
              {averageLabel}: {averageForming.toLocaleString()} (
              {formings.length})
            </Typography>
            <div className="visualization-items">
              <div className="visualization-item">
                <Visualization scale={scale} value={averageForming} />
              </div>
            </div>
          </div>
          <div className="visualization-wrapper">
            <Typography h2>{distributionLabel}</Typography>
            <div className="visualization-items">
              {formingsDistribution.map(({ value, count, percentage }, i) => {
                return (
                  <div
                    className="visualization-item"
                    key={`visualization-item-${i}`}
                  >
                    <Visualization scale={scale} value={value} />
                    <Typography h3>
                      {percentage.toLocaleString()}% ({count})
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default AggregatedScale;
