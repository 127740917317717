import { uniqueId } from "lodash-es";
import PropTypes from "prop-types";
import React from "react";
import GaugeChart from "react-gauge-chart";
import check from "../../../../../../static/images/check.svg";
import traffic_green from "../../../../../../static/images/traffic_green.svg";
import traffic_red from "../../../../../../static/images/traffic_red.svg";
import traffic_yellow from "../../../../../../static/images/traffic_yellow.svg";
import { VISUALIZATIONS_TYPES } from "../../../../constants";
import { Typography } from "../../../components";
import "./styles.scss";

export const COLORS = {
  3: ["#FF3333", "#FFCC00", "#66CC33"],
  4: ["#FF3333", "#FFCC00", "#95DF4C", "#66CC33"],
  5: ["#FF3333", "#FBA633", "#FFCC00", "#95DF4C", "#66CC33"],
  6: ["#FF3333", "#FBA633", "#FFCC00", "#DAFF01", "#95DF4C", "#66CC33"],
  7: [
    "#FF3333",
    "#FBA633",
    "#FEB814",
    "#FFCC00",
    "#DAFF01",
    "#95DF4C",
    "#66CC33",
  ],
};

export const getTrafficLight = (scaleFormingAmount, value) => {
  if (scaleFormingAmount === 2) {
    if (value < 1.5) {
      return {
        src: traffic_red,
        alt: "RED LIGHT",
      };
    } else {
      return {
        src: traffic_green,
        alt: "GREEN LIGHT",
      };
    }
  } else {
    if (value < 1.66) {
      return {
        src: traffic_red,
        alt: "RED LIGHT",
      };
    } else if (value < 2.33) {
      return {
        src: traffic_yellow,
        alt: "YELLOW LIGHT",
      };
    } else {
      return {
        src: traffic_green,
        alt: "GREEN LIGHT",
      };
    }
  }
};

function Visualization({ id, scale, value, percent }) {
  const scaleFormingAmount = scale.formingAmount;

  const chartStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <>
      {scale.formingVisualization === VISUALIZATIONS_TYPES.GAUGE && (
        <>
          <div className="tg_visualization-wrapper tg_gauge-wrapper">
            <GaugeChart
              id={id || uniqueId("gauge-chart-")}
              nrOfLevels={scaleFormingAmount}
              arcPadding={0.04}
              arcWidth={0.5}
              hideText={true}
              colors={COLORS[scaleFormingAmount]}
              needleColor="#162937"
              needleBaseColor="#162937"
              percent={
                percent
                  ? percent
                  : value / scaleFormingAmount - 1 / (2 * scaleFormingAmount)
              }
              style={chartStyle}
              animate={false}
            />
          </div>
          <div className="tg_center">
            <Typography h2 center>
              {scale[`forming${value}Text`]}
            </Typography>
          </div>
        </>
      )}
      {scale.formingVisualization === VISUALIZATIONS_TYPES.TRAFFIC_LIGHT && (
        <>
          <div className="tg_visualization-wrapper">
            {/*eslint-disable-next-line*/}
            <img
              className="traffic-light"
              {...getTrafficLight(scaleFormingAmount, value)}
            />
          </div>
          <div className="tg_center">
            <Typography h2 center>
              {scale[`forming${value}Text`]}
            </Typography>
          </div>
        </>
      )}
      {scale.formingVisualization === VISUALIZATIONS_TYPES.CATEGORY && (
        <div className="tg_visualization-wrapper tg_categories">
          {[...Array(scaleFormingAmount)].map((_, i) => (
            <div className="tg_category" key={`tg_category-${i}`}>
              {i + 1 === value && (
                <span className="tg_check-mark">
                  <img src={check} alt="checkMark" />
                </span>
              )}
              {scale[`forming${i + 1}Text`]}
            </div>
          ))}
        </div>
      )}
      {scale.formingVisualization === VISUALIZATIONS_TYPES.TEXT && (
        <div className="tg_visualization-wrapper">
          <div className="tg_center">
            <Typography h2 center>
              {scale[`forming${value}Text`]}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
}

Visualization.propTypes = {
  scale: PropTypes.object,
  id: PropTypes.string,
  value: PropTypes.number,
  percent: PropTypes.number,
};

export default Visualization;
