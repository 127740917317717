import { EntityResponse, TrafficLight } from "../types";
import {
  getQuestionsData,
  getTestDetails,
  getTestSetsData,
  getTrafficLights,
  getTrafficLightsDistribution,
} from "./utils";
import { useMemo } from "react";
import {
  TrafficLightsDistribution,
  QuestionsData,
  TestDetails,
  TestSetsData,
} from "./types";
import { getTestFlowsCountByEntries } from "../../AggregatedDashboard/utils";

export type HydratedData = {
  testFlowsCount: number;
  trafficLights: TrafficLight[];
  trafficLightsDistribution: TrafficLightsDistribution;
  testSetsData: TestSetsData;
  questionsData: QuestionsData;
  testDetails: TestDetails;
  hasReplies: boolean;
  shouldHideReport: boolean;
  testName: string;
};

export const useHydratedData = (
  entityResponse: EntityResponse | null,
  isIndividualReport = false
) => {
  return useMemo<HydratedData | null>(() => {
    if (!entityResponse) {
      return null;
    }

    const {
      entries,
      test: { name: testName },
      client,
    } = entityResponse;

    const hasReplies = !!entries.length;
    const testFlowsCount = getTestFlowsCountByEntries(
      entries,
      entityResponse.onlyCompleted
    );

    const minParticipantsNotReached = !(
      testFlowsCount >= (client?.reportMinParticipants || 0)
    ); // It's intended
    const shouldHideReport = !isIndividualReport && minParticipantsNotReached;

    const testSetsData = getTestSetsData(entityResponse);
    const questionsData = getQuestionsData(entityResponse, isIndividualReport);
    const trafficLights = getTrafficLights(testSetsData);
    const trafficLightsDistribution =
      getTrafficLightsDistribution(trafficLights);
    const testDetails = getTestDetails(testSetsData);

    return {
      testName,
      hasReplies,
      testFlowsCount,
      trafficLights,
      trafficLightsDistribution,
      testSetsData,
      questionsData,
      testDetails,
      shouldHideReport,
    };
  }, [entityResponse, isIndividualReport]);
};
