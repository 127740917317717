import { keyBy } from "lodash-es";
import { useMemo } from "react";
import { EMPTY_MATRIX } from "../constants";
import {
  DtoEntry,
  DtoField,
  DtoTestSet,
  FieldsConnectionMap,
  HydratedField,
  ProcessedMatrix,
} from "../types";
import { getStaticConnections } from "../utils";
import {
  correctColorAndOpacity,
  getColorsMapByTestSet,
  getFieldsOpacity,
  getTesSetsVisualisationValues,
} from "./field-utils";
import { GenericObject } from "../../../../@types";

export const FIELDS_STEP = 5;

export function useHydratedFields(
  fields: DtoField[],
  matrix: ProcessedMatrix<number>,
  connectionLabels: ProcessedMatrix<number>,
  testSets: DtoTestSet[],
  dtoEntries: DtoEntry[],
  entriesBySheetMap: GenericObject<Array<GenericObject>>
) {
  const fieldsMap = useMemo(() => {
    return keyBy(fields, "id");
  }, [fields]);

  const visualisationValues = useMemo(
    () => getTesSetsVisualisationValues(testSets, entriesBySheetMap),
    [testSets, entriesBySheetMap]
  );

  const testSetsColorsMap = useMemo(
    () => getColorsMapByTestSet(visualisationValues),
    [visualisationValues]
  );

  const staticFieldsConnectionsMap = useMemo<FieldsConnectionMap>(() => {
    if (matrix !== EMPTY_MATRIX && connectionLabels !== EMPTY_MATRIX) {
      return getStaticConnections(matrix, connectionLabels);
    }

    return EMPTY_MATRIX;
  }, [matrix, connectionLabels]);

  const fieldsOpacity = useMemo(() => {
    return getFieldsOpacity(fields, dtoEntries);
  }, [dtoEntries, fields]);

  const hydratedFields = useMemo(() => {
    return fields.map<HydratedField>(field => {
      const connectedFields = staticFieldsConnectionsMap[field.id].map(cn => {
        const dtoField = fieldsMap[cn.field];
        return {
          ...dtoField,
          ...correctColorAndOpacity(
            testSetsColorsMap[dtoField.sheetId],
            fieldsOpacity[cn.field]
          ),
          connectionValue: cn.value,
          connectionLabel: cn.label,
          size: cn.size,
        };
      });

      return {
        ...field,
        ...correctColorAndOpacity(
          testSetsColorsMap[field.sheetId],
          fieldsOpacity[field.id]
        ),
        visualisationValue: visualisationValues[field.sheetId],
        matrix,
        connectedFields,
      };
    });
  }, [
    fields,
    fieldsMap,
    matrix,
    staticFieldsConnectionsMap,
    visualisationValues,
    fieldsOpacity,
    testSetsColorsMap,
  ]);

  const hydratedFieldsMap = useMemo(() => {
    return keyBy(hydratedFields, "id");
  }, [hydratedFields]);

  return {
    hydratedFields,
    hydratedFieldsMap,
  };
}
