import styled from "styled-components";

const FlexColumns = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionsContainer = styled(FlexColumns)`
  margin-top: 20px;
`;

export const ChoicesContainer = styled(FlexColumns)``;

export const Question = styled.div`
  padding-bottom: 30px;
`;

export const Choice = styled.div`
  display: flex;
`;

export const QuestionTitle = styled.div`
  font-weight: bold;
  padding-bottom: 10px;
`;

export const ChoiceTitle = styled.div`
  flex-grow: 1;
  flex-basis: 420px;
`;

export const ChoiceNumber = styled.div`
  width: 90px;
  text-align: right;
`;

export const AverageContainer = styled.div`
  display: flex;
`;

export const AverageLabel = styled.div`
  flex-grow: 1;
  font-weight: bold;
  font-size: 20px;
`;
