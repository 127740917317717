import { Collapse } from "react-bootstrap";
import { Entries } from "../../../../../@types";
import { roundAndLocalizeNumber } from "../../../../../utils/formatting";
import { TRAFFIC_LIGHTS, TRAFFIC_LIGHTS_TITLES } from "../../constants";
import { TestDetails } from "../../hooks/types";
import { DetailsChartProps } from "../../types";

export const DetailsChart = ({
  testDetails,
  detailsOpen,
  tab,
  setTab,
  isIndividual,
}: DetailsChartProps) => {
  const handleTab = (i: number) => {
    setTab(i);
  };

  return (
    <Collapse in={detailsOpen}>
      <div className="tabs">
        <div className="tab-wrap">
          {(Object.entries(testDetails) as Entries<TestDetails>).map(
            ([k, v], i) => (
              <div
                className={`tab-head ${tab === i ? "active" : ""}`}
                key={k}
                onClick={() => handleTab(i)}
              >
                <img className="traffic-light" src={TRAFFIC_LIGHTS[k]} alt="" />
                <div className="traffic-light-text">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: TRAFFIC_LIGHTS_TITLES[k],
                    }}
                  />
                  <div className="traffic-light-number">{v.length}x</div>
                </div>
              </div>
            )
          )}
        </div>
        <div className="pb-4">
          {Object.entries(testDetails).map(([k, scales], i) => (
            <div
              key={k}
              className="tab-pane fade show active"
              style={{
                display: i === tab ? "block" : "none",
              }}
            >
              <ul className="list-1">
                <li style={{ borderTop: "none" }}>
                  <div className="row">
                    <div style={{ width: isIndividual ? "50%" : "40%" }}>
                      <span style={{ fontSize: "0.75em" }}>Themenbereich</span>
                    </div>
                    <div style={{ width: isIndividual ? "50%" : "40%" }}>
                      <span style={{ fontSize: "0.75em" }}>Thema</span>
                    </div>
                    {!isIndividual && (
                      <div style={{ width: "20%" }}>
                        <span style={{ fontSize: "0.75em" }}>Mittelwert</span>
                      </div>
                    )}
                  </div>
                </li>
                {scales.map(s => (
                  <li key={s.id}>
                    <div className="row">
                      <div style={{ width: isIndividual ? "50%" : "40%" }}>
                        <b>{s.testSetName}:</b>
                      </div>
                      <div style={{ width: isIndividual ? "50%" : "40%" }}>
                        {s.name}
                      </div>
                      {!isIndividual && (
                        <div style={{ width: "20%" }}>
                          {roundAndLocalizeNumber(s.avgValue)}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Collapse>
  );
};
