import { MultiValueOption } from "../../../../components/MultiSelect/types";

const encodeSelectedValuesAsBinaryString = (
  selectedItems: MultiValueOption[]
) => {
  const binaryArray: string[] = [];

  for (let i = 0; i < 32; i++) {
    binaryArray[i] = "0";
  }

  selectedItems.forEach(item => {
    const index = parseInt(item.value, 10);
    binaryArray[index] = "1";
  });

  return binaryArray.reverse().join("");
};

export const encodeSelectedValuesAsNumber = (
  selectedItems: MultiValueOption[]
) => {
  const binaryString = encodeSelectedValuesAsBinaryString(selectedItems);
  return parseInt(binaryString, 2);
};
