import styled from "styled-components";

export const CockpitWrapper = styled.div`
  display: flex;
  height: 100%;
  background-color: #fff;
`;

export const RadarWrapper = styled.div`
  width: 60%;
  height: 100%;
`;

export const FieldsDetailsWrapper = styled.div`
  overflow: hidden;

  .visualization-items .visualization-item {
    margin-right: 10px !important;
  }

  .tg_gauge-wrapper {
    width: 220px;
  }

  .traffic-light {
    width: 150px;
  }
`;

export const StepWrapper = styled("div")`
  position: absolute;
  z-index: 1;
  width: 40%;
  right: 0;
  height: 100%;
  min-height: 768px;
  background: #fff;
  box-shadow: -10px 0px 10px 1px #00000055;
  overflow: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1366px) {
    padding: 30px 40px;
  }
`;
