import React, { useContext, useMemo, useState } from "react";
import { debounce } from "lodash-es";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import { UPDATE_TEST_ENTRIES_SUCCESS } from "../../../../../store/actions";
import { Context } from "../../../../../store/store";
import { mergeEntries } from "../../../../../store/utils";
import { API_URLS } from "../../../../../utils/api-constants";
import { axiosPost } from "../../../../../utils/queries";
import { Button } from "../../components";
import {
  getGroupIdFromUrl,
  getTestUrlForSession,
  getUnAnsweredQuestionIndex,
  isLastTestSet,
  smoothScrollIntoView,
  smoothScrollTo,
  testHasRegularAttributes,
  testHasSpecialAttributes,
} from "./../../utils";
import { oneTimeAxiosPost } from "./request-utils";
import "./styles.scss";
import TestItem from "./TestItem";
import { useTranslation } from "react-i18next";

const INDEX_DATA_ATTR = "data-question-index";

function Test() {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const { testSetId, testId } = useParams();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [requiredItemIndex, setRequiredItemIndex] = useState(-1);
  const {
    publicTest: { sets, entries, nextButton, finishButton },
    testSessionData: { sessionId },
  } = state;

  const buttonLabel =
    !isLastTestSet(state.publicTest) ||
    testHasRegularAttributes(state.publicTest) ||
    testHasSpecialAttributes(state.publicTest)
      ? nextButton
      : finishButton;

  const currentSet = useMemo(() => {
    return sets.find(set => set._id === testSetId);
  }, [testSetId, sets]);

  const currentEntries = useMemo(() => {
    return entries.find(e => e.testSetId === currentSet._id);
  }, [entries, currentSet]);

  const unAnsweredQuestionIndex = getUnAnsweredQuestionIndex(
    currentSet,
    currentEntries
  );

  function getIsCompleted() {
    return unAnsweredQuestionIndex === -1;
  }

  const updatePartialData = useMemo(
    () =>
      debounce(data => {
        oneTimeAxiosPost(API_URLS.PUBLIC_TEST, { data })
          .then(() => {
            setIsSaving(false);
          })
          .catch(e => {
            toast.error(
              t(
                "frontend.networkError",
                "Error: Blocked by Firewall or Internet connection error. Please contact your IT administrator"
              )
            );
            setIsSaving(false);
          });
      }, 500),
    [setIsSaving, t]
  );

  function saveEntireDataSet() {
    const data = {
      ...currentEntries,
      testId,
      testSetId: currentSet._id,
      sessionId: sessionId,
      completed: getIsCompleted(),
    };

    return axiosPost(API_URLS.PUBLIC_TEST, { data })
      .then(payload => {
        dispatch({ type: UPDATE_TEST_ENTRIES_SUCCESS, payload });
        return {
          ...state.publicTest,
          entries: mergeEntries(payload, state.publicTest.entries),
        };
      })
      .catch(e => {
        // todo handle errors sentry;
      });
  }

  function handleItemChange(data, isTextInput) {
    setRequiredItemIndex(-1);

    const body = {
      ...currentEntries,
      ...data,
      testId,
      testSetId: currentSet._id,
      sessionId: sessionId,
      groupId: getGroupIdFromUrl(),
    };

    dispatch({ type: UPDATE_TEST_ENTRIES_SUCCESS, payload: body });

    if (!isTextInput) {
      setIsSaving(true);
      updatePartialData(body);
    }
  }

  function handleNextClick() {
    if (unAnsweredQuestionIndex === -1) {
      setIsSaving(true);
      saveEntireDataSet().then(publicTest => {
        setIsSaving(false);
        history.push(getTestUrlForSession(publicTest, sessionId));
        smoothScrollTo(0);
      });
    } else {
      setRequiredItemIndex(unAnsweredQuestionIndex);
      const elements = document.querySelectorAll(
        `[${INDEX_DATA_ATTR}="${unAnsweredQuestionIndex}"]`
      );

      if (elements.length) {
        smoothScrollIntoView(elements[0]);
      }
    }
  }

  if (!currentSet) {
    return <div>Error in set loading...</div>;
  }

  return (
    <div className="tg_set-page tg_page">
      <div
        className="tg_info"
        dangerouslySetInnerHTML={{ __html: currentSet.info.info }}
      />
      <div className="tg_column">
        {currentSet.items.map((item, i) => (
          <TestItem
            {...{ [INDEX_DATA_ATTR]: i }}
            key={i}
            className={classNames("tg_form-item", {
              "is-required": item.mandatory && i === requiredItemIndex,
            })}
            item={item}
            initialValue={currentEntries?.[`${item.dbColumn}`] || ""}
            onChange={handleItemChange}
          />
        ))}
        <Button onClick={handleNextClick} large disabled={isSaving}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}

export default Test;
