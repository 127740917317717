import { components, MultiValueProps } from "react-select";
import { MultiValueOption } from "../../types";

export const MultiValue = (props: MultiValueProps<MultiValueOption>) => {
  const {
    data: { isHiddenInSelection },
  } = props;

  if (isHiddenInSelection) {
    return null;
  }

  return <components.MultiValue {...props} />;
};
