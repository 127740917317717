import { forwardRef } from "react";
import striptags from "striptags";
import { renderToString } from "react-dom/server";
import { InfoIcon } from "../icons";
import { SvgContainer, Text, TextContainer } from "./styled";
import { TagProps } from "./types";

const Tag = forwardRef(
  (
    {
      field: { name, description, quadrant, color },
      x,
      y,
      lines,
      bgOpacity,
      isTagClickEnabled,
      isTooltipEnabled,
      isCurrent,
      onClick,
    }: TagProps,
    ref: any
  ) => {
    const backgroundColor = `rgba(${color}, ${bgOpacity})`;
    const borderColor = isCurrent
      ? "var(--current-tag-border)"
      : `rgba(${color}, ${bgOpacity})`;

    return (
      <SvgContainer
        ref={ref}
        width="190"
        height="37"
        viewBox="0 0 190 37"
        x={x}
        y={y}
        isTagClickEnabled={isTagClickEnabled}
        isTooltipEnabled={isTooltipEnabled}
        onClick={onClick}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      >
        <path
          d="M202.69,472.005a17.645,17.645,0,0,0-17.77-17.5H32.46a17.5,17.5,0,1,0,0,35H184.92a17.645,17.645,0,0,0,17.77-17.5Z"
          transform="translate(-13.69, -453.505)"
          fill="rgba(255, 255, 255, 1)"
          strokeWidth="0"
          fillRule="evenodd"
        />
        <path
          d="M202.69,472.005a17.645,17.645,0,0,0-17.77-17.5H32.46a17.5,17.5,0,1,0,0,35H184.92a17.645,17.645,0,0,0,17.77-17.5Z"
          transform="translate(-13.69, -453.505)"
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth="2"
          fillRule="evenodd"
        />
        <g>
          <foreignObject x="1" y="1" width="188" height="35">
            <TextContainer
              data-html={true}
              data-multiline={true}
              data-arrow-color="#fff"
              data-background-color="#fff"
              data-tip-disable={!isTooltipEnabled}
              data-tip={renderToString(
                <div
                  style={{
                    color: "var(--tooltip-color)",
                    maxWidth: "300px",
                    padding: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontWeight: "bold",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <InfoIcon />
                    {striptags(name, [], " ")}
                  </div>
                  <div
                    style={{
                      lineHeight: "19px",
                    }}
                  >
                    {description}
                  </div>
                </div>
              )}
            >
              <Text
                lines={lines}
                sizeMultiplier={1}
                isSelected={
                  false
                } /*With warning same behaviour as with selected*/
                length={name.length}
                dangerouslySetInnerHTML={{ __html: name }}
              ></Text>
            </TextContainer>
          </foreignObject>
        </g>
      </SvgContainer>
    );
  }
);

export default Tag;
