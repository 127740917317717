import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { UPDATE_CURRENT_SESSION_TEST_DATA } from "../../../../../store/actions";
import { Context } from "../../../../../store/store";
import { PUBLIC_TEST_URL } from "../../../constants";
import { buildUrlWithGetParams, canStartTest } from "../../utils";
import Button from "./../../components/button";
import "./styles.scss";

function Test() {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {
    publicTest: { _id, welcomeHtml, startButton, sets, entries },
    testSessionData,
  } = state;

  const onStartButtonClick = () => {
    if (
      !(
        (
          entries &&
          entries.length &&
          entries[0].sessionId === testSessionData.sessionId
        ) // check if we have entries with same session ID, then continue
      )
    ) {
      const sessionId = uuidv4();
      dispatch({
        type: UPDATE_CURRENT_SESSION_TEST_DATA,
        payload: { sessionId },
      });
    }

    history.push(
      buildUrlWithGetParams(`${PUBLIC_TEST_URL}/${_id}/${sets[0]._id}`)
    );
  };

  return (
    <div className="tg_page tg_test-info">
      <div
        className="tg_test-welcome-html"
        dangerouslySetInnerHTML={{ __html: welcomeHtml }}
      />
      <div className="tg_column">
        <Button
          large
          onClick={onStartButtonClick}
          disabled={!canStartTest(state.publicTest)}
        >
          {startButton}
        </Button>
      </div>
    </div>
  );
}

export default Test;
