import { Person } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SET_SELECTED_CLIENT_ID } from "../../../../store/actions";
import { Context } from "../../../../store/store";
import { API_URLS } from "../../../../utils/api-constants";
import { initiateClientsMap } from "../../constants";
import { useGet } from "../../hooks";

function ClientSelector() {
  const { t } = useTranslation();
  const clientsMap = initiateClientsMap(t);
  const [state, dispatch] = useContext(Context);
  const [anchorEl, setAnchorEl] = useState(null);
  const getClients = useGet(API_URLS.CLIENTS);
  const open = Boolean(anchorEl);

  const selectedClientName = (() => {
    if (clientsMap.has(state.selectedClientId)) {
      return clientsMap.get(state.selectedClientId);
    }
    return getClients?.data?.find(c => c._id === state.selectedClientId)?.name;
  })();

  function handleClickListItem(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuItemClick(id) {
    dispatch({
      type: SET_SELECTED_CLIENT_ID,
      payload: clientsMap.has(id)
        ? id
        : getClients?.data?.find(c => c._id === id)?._id,
    });
    setAnchorEl(null);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function renderItem(text, selected) {
    return selected ? <b>{text}</b> : text;
  }

  return (
    <>
      <Box onClick={handleClickListItem} sx={{ cursor: "pointer" }}>
        <Typography
          variant="subtitle1"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#fff",
            marginTop: "10px",
            gap: "10px",
            whiteSpace: "nowrap",
          }}
        >
          <Person />
          <Box display="flex" alignItems="center">
            {selectedClientName}
            <ArrowDropDownIcon />
          </Box>
        </Typography>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => handleMenuItemClick(undefined)}
          selected={state.selectedClientId === undefined}
        >
          {renderItem(
            clientsMap.get(undefined),
            state.selectedClientId === undefined
          )}
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("null")}
          selected={state.selectedClientId === "null"}
        >
          {renderItem(
            clientsMap.get("null"),
            state.selectedClientId === "null"
          )}
        </MenuItem>
        {getClients?.data?.map(c => (
          <MenuItem
            key={c._id}
            value={c._id}
            onClick={() => handleMenuItemClick(c._id)}
            selected={state.selectedClientId === c._id}
          >
            {renderItem(c.name, state.selectedClientId === c._id)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ClientSelector;
