import { Entries } from "../../../../../@types";
import { roundAndLocalizeNumber } from "../../../../../utils/formatting";
import { BAR_COLORS } from "../../constants";
import { TrafficLightsDistribution } from "../../hooks/types";
import { DistributionProps } from "../../types";

export const DistributionChart = ({
  trafficLightsDistribution,
}: DistributionProps) => {
  return (
    <div className="chart-item">
      <div className="chart-item-title">Verteilung</div>
      <div className="bars-container text-center">
        {(
          Object.entries(
            trafficLightsDistribution
          ) as Entries<TrafficLightsDistribution>
        ).map(([k, v]) => (
          <div key={k}>
            <div
              className="bar"
              style={{
                background: "#e6e7e9",
              }}
            >
              <div
                className="bar-inner"
                style={{
                  height: `${v.percentage}%`,
                  backgroundColor: BAR_COLORS[k],
                }}
              />
            </div>
            <div className="bar-cap">
              {roundAndLocalizeNumber(v.percentage, 0)}%
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
