import { useEffect, useState } from "react";
import { ReportHeader } from "./ReportHeader";
import { ReportChartWrapper } from "./ReportChartWrapper";
import { AccordionWrapper } from "./Accordion/AccordionWrapper";
import { ReportFarewell } from "./ReportFarewell";
import { ReportProps } from "../types";
import "./../styles.scss";
import { tryEmbedCssLink } from "../../../../utils/embed-utils";

export const Report = ({
  data,
  isIndividual = false,
  FiltersComponent,
}: ReportProps) => {
  const [bootstrapLoaded, setBootstrapLoaded] = useState(false);

  useEffect(() => {
    tryEmbedCssLink(
      "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css",
      () => setBootstrapLoaded(true)
    );
  }, []);

  if (!bootstrapLoaded) {
    return null;
  }

  return (
    <div className="custom-reports">
      <div className="custom-reports-wrap">
        <ReportHeader data={data} isIndividual={isIndividual} />
        {FiltersComponent}
        {!data.shouldHideReport && data.hasReplies && (
          <>
            <ReportChartWrapper data={data} isIndividual={isIndividual} />
            <AccordionWrapper data={data} isIndividual={isIndividual} />
            {isIndividual && <ReportFarewell />}
          </>
        )}

        <div className="text-end">
          <img alt="" />
        </div>
      </div>
    </div>
  );
};
