import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { axiosGet } from "../../../utils/queries";
import { getGroupUrl, getReportUrl } from "../AggregatedDashboard/utils";
import { useHydratedData } from "./hooks/useHydratedData";
import { Report } from "./components/Report";
import { EntityResponse } from "./types";
import {
  setFavIcon,
  tryEmbedCssLink,
  tryEmbedScript,
} from "../../../utils/embed-utils";
import { AttributeFilters } from "../components/AttributeFilters";
import { useFilteredResponse } from "./hooks/useFilteredResponse";

type CustomReportsProps = {
  isGroup: boolean;
};

const CustomReports = ({ isGroup }: CustomReportsProps) => {
  const { entityId } = useParams<{ entityId: string }>();
  const entityApiUrl = isGroup ? getGroupUrl(entityId) : getReportUrl(entityId);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<EntityResponse | null>(null);
  const [, setCustomFuncLoaded] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  useEffect(() => {
    if (data?.client?.favIconUrl) {
      setFavIcon(data.client.favIconUrl);
    }
  }, [data]);

  useEffect(() => {
    axiosGet(entityApiUrl).then(responseData => {
      const resData = responseData as unknown as EntityResponse; // TODO refactor typing
      setData(resData);
      setIsLoading(false);

      if (resData.client.isSpecialReport) {
        tryEmbedScript(resData.test.customReportUrl, () => {
          setCustomFuncLoaded(true);
        });

        tryEmbedCssLink(resData.client.reportCssUrl);
      }

      if (resData.test.name) {
        document.title = resData.test.name;
      }
    });
  }, [entityApiUrl]);

  const filteredData = useFilteredResponse(data, selectedAttributes);
  const hydratedData = useHydratedData(filteredData);

  if (isLoading || !hydratedData) {
    return <Loader />;
  }

  return (
    <Report
      data={hydratedData}
      FiltersComponent={
        data?.client.reportAttrFilters && (
          <AttributeFilters
            test={data!.test}
            onChange={attrs => {
              setSelectedAttributes(attrs);
            }}
          />
        )
      }
    />
  );
};

export default CustomReports;
