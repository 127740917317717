import { CancelToken } from "axios";
import request from "./api";

function createCancellableRequest(axiosConfig) {
  const source = CancelToken.source();

  let promise = request({
    ...axiosConfig,
    cancelToken: source.token,
  });

  promise = promise.then(({ data }) => data);

  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
}

export const axiosGet = (url, options) =>
  createCancellableRequest({ url, method: "GET", ...options });
export const axiosPost = (url, options) =>
  createCancellableRequest({ url, method: "POST", ...options });
export const axiosPut = (url, options) =>
  createCancellableRequest({ url, method: "PUT", ...options });
export const axiosPatch = (url, options) =>
  createCancellableRequest({ url, method: "PATCH", ...options });
export const axiosDelete = (url, options) =>
  createCancellableRequest({ url, method: "DELETE", ...options });
