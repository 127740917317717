import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Button,
  Container,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { LOGIN_SUCCESS } from "../../../store/actions";
import { Context } from "../../../store/store";
import { login } from "../../../utils/api";
import "./styles.scss";

function Login() {
  const { t } = useTranslation();
  const [state, dispatch] = useContext(Context);

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [hasLoginError, setHasLoginError] = useState(false);

  const handleForm = (e, field) => {
    const updated = { ...data };
    updated[field] = e.target.value;
    setData(updated);
  };

  const handleSubmit = e => {
    e.preventDefault();
    login(data)
      .then(response => {
        if (response.status === 200 && response.data && response.data.token) {
          dispatch({ type: LOGIN_SUCCESS, payload: response.data });
        }
      })
      .catch(() => setHasLoginError(true));
  };

  if (state.isAuthenticated) {
    return <Redirect to="/tool/tests" />;
  }

  return (
    <Container maxWidth="xs" className="login-page">
      <Snackbar
        open={hasLoginError}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setHasLoginError(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          Unable to sign in
        </MuiAlert>
      </Snackbar>
      <div className="login-box">
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("tool.login.signIn", "Sign in")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("tool.login.email", "Email Address")}
            name="email"
            autoComplete="email"
            autoFocus
            value={data.email}
            onChange={e => handleForm(e, "email")}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("tool.login.password", "Password")}
            type="password"
            id="password"
            autoComplete="current-password"
            value={data.password}
            onChange={e => handleForm(e, "password")}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!data.email || !data.password}
          >
            {t("tool.login.signInBtn", "Sign in")}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default Login;
