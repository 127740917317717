import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import { ADD_CLIENT_URL, CLIENTS_URL } from "../constants";
import { useDelete, useGet } from "../hooks";

function ClientList() {
  const history = useHistory();
  const { t } = useTranslation();
  const getClients = useGet(API_URLS.CLIENTS);
  const deleteClient = useDelete(API_URLS.CLIENTS);
  const confirm = useConfirm();

  function onAddClientButtonClick() {
    history.push(ADD_CLIENT_URL);
  }

  return (
    <Container maxWidth={false} className="list-page">
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        onClick={onAddClientButtonClick}
      >
        {t("tool.clientsList.newClient", "New Client")}
      </Button>
      {(getClients.isLoading || deleteClient.isLoading) && <Loader />}
      {getClients.error && (
        <Alert severity="error">
          {t("tool.general.error.dataFetching", "Data fetching error.")}
        </Alert>
      )}
      <TableContainer component={Paper} className="table-container">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("tool.clientsList.id", "ID")}</TableCell>
              <TableCell>{t("tool.clientsList.name", "Name")}</TableCell>
              <TableCell align="right">
                {t("tool.clientsList.actions", "Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getClients?.data?.map(client => (
              <TableRow key={client._id}>
                <TableCell component="th" scope="row">
                  {client._id}
                </TableCell>
                <TableCell align="left">{client.name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => {
                      history.push(`${CLIENTS_URL}/${client._id}`);
                    }}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => {
                      confirm({
                        title: t(
                          "tool.clientsList.confirmDeletionTitle",
                          "Remove Confirmation"
                        ),
                        description: t(
                          "tool.clientsList.confirmDeletionDesc",
                          "Are you sure you want to delete {{name}} ?",
                          { name: client.name }
                        ),
                      })
                        .then(() => deleteClient.mutateAsync(client._id))
                        .catch(() => {});
                    }}
                    size="large"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default ClientList;
