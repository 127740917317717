import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import FrontendPage from "./Frontend";
import ToolPage from "./Tool";

import "react-toastify/dist/ReactToastify.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function LayoutRouter() {
  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <ReactTooltip globalEventOff="click" delayShow={400} />
      <Switch>
        <Route path="/tool" component={ToolPage} />
        <Route path="/" component={FrontendPage} />
      </Switch>
    </Router>
  );
}

export default LayoutRouter;
