import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import { useGet, usePost } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import TestForm from "./Form";

function DuplicateTest() {
  const { id } = useParams();
  const getTest = useGet(`${API_URLS.TESTS}/${id}`);
  const createTest = usePost(`${API_URLS.TESTS}`);

  if (getTest.isLoading) {
    return <Loader />;
  }
  return (
    <PageWrapper>
      <TestForm
        test={getTest.data}
        error={createTest.error || getTest.error}
        loading={createTest.isLoading || getTest.isLoading}
        onSubmit={createTest.mutateAsync}
        duplicateMode
      />
    </PageWrapper>
  );
}

export default DuplicateTest;
