import { AppBar, Box, Tab, Tabs } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import AddClient from "../Client/Add";
import EditClient from "../Client/Edit";
import ClientList from "../Client/List";
import {
  ADD_CLIENT_URL,
  ADD_GROUP_URL,
  ADD_REPORT_URL,
  ADD_TEST_URL,
  CLIENTS_URL,
  ENTRIES_URL,
  FILTER_BY_CLIENT,
  GROUP_URL,
  REPORT_URL,
  TESTS_URL,
} from "../constants";
import EntriesList from "../EntriesList";
import AddGroup from "../Group/Add";
import EditGroup from "../Group/Edit";
import GroupList from "../Group/List";
import AddReport from "../Report/Add";
import EditReport from "../Report/Edit";
import ReportList from "../Report/List";
import ClientSelector from "../Shared/ClientSelector";
import AddTest from "../Test/Add";
import DuplicateTest from "../Test/Duplicate";
import EditTest from "../Test/Edit";
import TestsList from "../Test/List";
import "./styles.scss";

function Tool() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(TESTS_URL);

  useEffect(() => {
    let { pathname } = location;

    if (pathname.includes(ENTRIES_URL)) {
      pathname = ENTRIES_URL;
    } else if (pathname.includes(REPORT_URL)) {
      pathname = REPORT_URL;
    } else if (pathname.includes(CLIENTS_URL)) {
      pathname = CLIENTS_URL;
    } else if (pathname.includes(GROUP_URL)) {
      pathname = GROUP_URL;
    } else {
      pathname = TESTS_URL;
    }

    setSelectedTab(pathname);
  }, [location]);

  function handleNavChange(event, tabUrl) {
    history.push(tabUrl);
    setSelectedTab(tabUrl);
  }

  return (
    <ConfirmProvider>
      <div className="page tool-page">
        <AppBar position="static">
          <Box display="flex" justifyContent="space-between">
            <Tabs
              value={selectedTab}
              onChange={handleNavChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                "@media (min-width: 1024px)": {
                  ".MuiTab-root": {
                    minWidth: 160,
                  },
                },
              }}
            >
              <Tab
                label={t("tool.general.header.tests", "Tests")}
                value={TESTS_URL}
              />
              <Tab
                label={t("tool.general.header.entries", "Entries")}
                value={ENTRIES_URL}
              />
              <Tab
                label={t("tool.general.header.reports", "Reports")}
                value={REPORT_URL}
              />
              <Tab
                label={t("tool.general.header.clients", "Clients")}
                value={CLIENTS_URL}
              />
              <Tab
                label={t("tool.general.header.groups", "Groups")}
                value={GROUP_URL}
              />
            </Tabs>
            {FILTER_BY_CLIENT[location.pathname] && (
              <Box mr="24px">
                <ClientSelector />
              </Box>
            )}
          </Box>
        </AppBar>
        <Switch>
          <Route exact path="/tool">
            <Redirect to="/tool/tests" />
          </Route>
          <Route exact path={TESTS_URL} component={TestsList} />
          <Route exact path={ADD_TEST_URL} component={AddTest} />
          <Route exact path={`${TESTS_URL}/:id`} component={EditTest} />
          <Route
            exact
            path={`${TESTS_URL}/:id/duplicate`}
            component={DuplicateTest}
          />
          <Route path={ENTRIES_URL} component={EntriesList} />

          <Route exact path={REPORT_URL} component={ReportList} />
          <Route exact path={ADD_REPORT_URL} component={AddReport} />
          <Route exact path={`${REPORT_URL}/:id`} component={EditReport} />

          <Route exact path={GROUP_URL} component={GroupList} />
          <Route exact path={ADD_GROUP_URL} component={AddGroup} />
          <Route exact path={`${GROUP_URL}/:id`} component={EditGroup} />

          <Route exact path={CLIENTS_URL} component={ClientList} />
          <Route exact path={ADD_CLIENT_URL} component={AddClient} />
          <Route exact path={`${CLIENTS_URL}/:id`} component={EditClient} />
        </Switch>
      </div>
    </ConfirmProvider>
  );
}

export default Tool;
