import { ClickableText } from "./styled";

function Long({ onClick }: any) {
  return (
    <g id="_2_state" data-name="2 state" transform="translate(-13)">
      <g
        id="Rectangle_415"
        data-name="Rectangle 415"
        transform="translate(659 986)"
        fill="#fff"
        stroke="#7f7f7f"
        strokeWidth="1"
      >
        <rect width="296" height="40" rx="20" stroke="none" />
        <rect x="0.5" y="0.5" width="295" height="39" rx="19.5" fill="none" />
      </g>
      <g
        id="Rectangle_416"
        data-name="Rectangle 416"
        transform="translate(812 986)"
        fill="#ccc"
        stroke="#7f7f7f"
        strokeWidth="3"
      >
        <rect width="143" height="40" rx="20" stroke="none" />
        <rect x="1.5" y="1.5" width="140" height="37" rx="18.5" fill="none" />
      </g>
      <ClickableText
        onClick={onClick}
        id="Performanz"
        transform="translate(677 1012)"
        fill="rgba(0,0,0,0.5)"
        fontSize="18"
        fontFamily="Roboto-Regular, Roboto"
      >
        <tspan x="0" y="0">
          PERFORMANZ
        </tspan>
      </ClickableText>
      <text
        id="Innovation"
        transform="translate(937 1012)"
        fill="rgba(0,0,0,0.5)"
        fontSize="18"
        fontFamily="Roboto-Bold, Roboto"
        fontWeight="700"
      >
        <tspan x="-106.585" y="0">
          INNOVATION
        </tspan>
      </text>
    </g>
  );
}
export default Long;
