import { useState } from "react";
import { ResultChart } from "./Charts/ResultChart";
import { DistributionChart } from "./Charts/DistributionChart";
import { DetailsChart } from "./Charts/DetailsChart";
import { ReportProps } from "../types";

export const ReportChartWrapper = ({ data, isIndividual }: ReportProps) => {
  const [tab, setTab] = useState(0);
  const [detailsOpen, setDetailsOpen] = useState(false);

  return (
    <>
      <div className="chart">
        <ResultChart trafficLights={data.trafficLights} />
        <DistributionChart
          trafficLightsDistribution={data.trafficLightsDistribution}
        />
      </div>

      <div
        className="details-button"
        onClick={() => setDetailsOpen(!detailsOpen)}
      >
        Details
      </div>

      <DetailsChart
        testDetails={data.testDetails}
        detailsOpen={detailsOpen}
        tab={tab}
        setTab={setTab}
        isIndividual={!!isIndividual}
      />
    </>
  );
};
