import React from "react";
import { API_URLS } from "../../../utils/api-constants";
import { usePost } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import ClientForm from "./Form";

function AddClient() {
  const createClient = usePost(API_URLS.CLIENTS);

  return (
    <PageWrapper>
      <ClientForm
        client={createClient.data}
        loading={createClient.isLoading}
        error={createClient.error}
        onSubmit={createClient.mutateAsync}
      />
    </PageWrapper>
  );
}

export default AddClient;
