import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import { useGet, usePut } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import TestForm from "./Form";

function EditTest() {
  const { id } = useParams();
  const getTest = useGet(`${API_URLS.TESTS}/${id}`);
  const updateTest = usePut(`${API_URLS.TESTS}/${id}`);

  if (getTest.isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <TestForm
        test={getTest.data}
        error={updateTest.error || getTest.error}
        loading={updateTest.isLoading || getTest.isLoading}
        onSubmit={updateTest.mutateAsync}
        editMode
      />
    </PageWrapper>
  );
}

export default EditTest;
