import { GenericObject } from "../../../../@types";
import { MAX_ATTRIBUTES_COUNT } from "../../constants";
import { Attribute } from "./types";

export function parseAttributes(test: GenericObject, attributesPrefix: string) {
  const attrs: Attribute[] = [];
  for (let i = 1; i <= MAX_ATTRIBUTES_COUNT; i++) {
    const name = `${attributesPrefix}${i}`;
    const label = test[`${name}Label`];
    const options = (test[`${name}Value`] || []).filter((opt: string) => !!opt);

    if (label && options.length) {
      attrs.push({
        name,
        label,
        options,
      });
    }
  }

  return attrs;
}
