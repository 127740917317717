import { Accordion } from "react-bootstrap";
import { AccordionPersonalItem } from "./AccordionPersonalItem";
import { AccordionItems } from "./AccordionItems";
import { ReportProps } from "../../types";

export const AccordionWrapper = ({
  data,
  isIndividual = false,
}: ReportProps) => {
  return (
    <Accordion alwaysOpen flush className="accardion-wrap">
      <AccordionPersonalItem data={data} isIndividual={isIndividual} />
      <AccordionItems data={data} isIndividual={isIndividual} />
    </Accordion>
  );
};
