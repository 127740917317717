import i18n from "i18next";

// Don't delete this, it is used for translation extraction script
const serverMessagesTranslationMap = {
  "server.errorMessages.invalidTesSet": () =>
    i18n.t("server.errorMessages.invalidTesSet", "Invalid Test-Set"),
};

export function getTranslatedServerMessage(key) {
  if (serverMessagesTranslationMap[key]) {
    return serverMessagesTranslationMap[key]();
  }

  return key;
}
