import { EntityResponse } from "../types";
import { GenericObject } from "../../../../@types";
import { Attribute } from "../../components/AttributeFilters/types";
import { useMemo } from "react";
import { filterEntries } from "../../utils";

export const useFilteredResponse = (
  response: EntityResponse | null,
  attributes: GenericObject<Attribute>
) => {
  return useMemo(() => {
    if (!response) {
      return null;
    }

    return {
      ...response,
      entries: filterEntries(response.entries, attributes),
    };
  }, [response, attributes]);
};
