import classNames from "classnames";
import React from "react";
import "./styles.scss";

function Select(props) {
  const { className, options, ...rest } = props;
  return (
    <select {...rest} className={classNames("tg_select", className)}>
      <option value="" disabled />
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}

export default Select;
