import { MultiValueOption } from "./types";
import { ActionMeta } from "react-select/dist/declarations/src/types";
import { TOGGLE_ALL_VALUE } from "./constants";

export function getOptionsWithoutHiddenItems(options: MultiValueOption[]) {
  return options.filter(opt => !opt.isHiddenInSelection);
}

export function getNewSelectedOptions(
  originalOptions: MultiValueOption[],
  allOptions: MultiValueOption[],
  filteredOptions: MultiValueOption[],
  selectedOptions: MultiValueOption[],
  actionMeta: ActionMeta<MultiValueOption>
) {
  if (actionMeta.option?.value === TOGGLE_ALL_VALUE) {
    if (actionMeta.action === "deselect-option") {
      return [];
    } else {
      return allOptions;
    }
  } else {
    if (filteredOptions.length === originalOptions.length) {
      return allOptions;
    } else {
      return selectedOptions.filter(opt => opt.value !== TOGGLE_ALL_VALUE);
    }
  }
}
