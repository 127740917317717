import { Box, Grid, TextField } from "@mui/material";
import { FastField, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

function Attributes({ ids, prefix }) {
  const { touched, errors } = useFormikContext();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      {ids.map(id => (
        <Grid item xs={12} sm key={`${prefix}${id}`}>
          <FastField
            name={`${prefix}${id}Label`}
            as={TextField}
            label={t("tool.addEdit.attrLabel", "Attribute {{id}}", { id })}
            fullWidth
            variant="outlined"
            error={
              touched[`${prefix}${id}Label`] &&
              Boolean(errors[`${prefix}${id}Label`])
            }
            helperText={
              touched[`${prefix}${id}Label`] && errors[`${prefix}${id}Label`]
            }
          />
          <Box height="20px" />
          <FastField
            name={`${prefix}${id}Value`}
            as={TextField}
            label={t("tool.addEdit.attrValue", "Attribute {{id}} value", {
              id,
            })}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            error={
              touched[`${prefix}${id}Value`] &&
              Boolean(errors[`${prefix}${id}Value`])
            }
            helperText={
              touched[`${prefix}${id}Value`] && errors[`${prefix}${id}Value`]
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default Attributes;
