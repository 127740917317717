import { roundAndLocalizeNumber } from "../../../../../utils/formatting";
import { AccordionItemProps } from "../../types";

export const AccordionGroupItem = ({ question }: AccordionItemProps) => {
  return (
    <li>
      <div className="custom-grid">
        {Object.entries(question.entries).map(([k, v], i) => (
          <div
            key={k}
            className={`column-${i + 1} opacity-${!v && 1}`}
            style={{ width: `${v}%` }}
          >
            {v > 0 && (
              <span className="percentage">
                {roundAndLocalizeNumber(v, 0)}%
              </span>
            )}
          </div>
        ))}
      </div>
      {question.text}
      <div>
        <span className="traffic-light-number">
          Ø {roundAndLocalizeNumber(question.average)}
        </span>
      </div>
    </li>
  );
};
