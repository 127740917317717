import React, { useRef } from "react";
import { MultiSelect } from "../MultiSelect";
import { AttributeFilterProps, Attribute } from "./types";
import { useAttributes, useSelectAttributes } from "./hooks";
import { GenericObject } from "../../../../@types";

export function AttributeFilters({ test, onChange }: AttributeFilterProps) {
  const attributes = useAttributes(test);
  const selectAttributes = useSelectAttributes(attributes);
  const selectedAttributesRef = useRef<GenericObject<Attribute>>({});

  function handleOnChange(name: string, label: string, options: string[]) {
    const selectedAttributes = selectedAttributesRef.current;

    if (options.length) {
      selectedAttributes[name] = {
        name,
        label,
        options,
      };
    } else {
      delete selectedAttributes[name];
    }

    onChange({ ...selectedAttributes });
  }

  if (!selectAttributes.length) {
    return null;
  }

  return (
    <div className="tg_attribute_filters">
      {selectAttributes.map(({ selectOptions, name, label }) => (
        <div className="tg_attribute_filter" key={name}>
          <div className="tg_attribute_name">{label}</div>
          <MultiSelect
            options={selectOptions}
            onChange={selectedOptions => {
              handleOnChange(
                name,
                label,
                selectedOptions.map(opt => opt.value)
              );
            }}
          />
        </div>
      ))}
    </div>
  );
}
