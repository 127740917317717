export const parseAttributeValue = attr => {
  const attrArray = attr.split(":");
  const label = attrArray[0];
  const value = attrArray[1];
  const parent = attrArray[2];

  return { value: value || label, label, parent };
};

export const formatSelectOptions = attributes =>
  attributes.map(parseAttributeValue);
