import { useMemo } from "react";
import { Attribute } from "./types";
import { parseAttributes } from "./utils";
import { GenericObject } from "../../../../@types";

export function useAttributes(test: GenericObject) {
  return useMemo<Attribute[]>(() => {
    const regularAttributes = parseAttributes(test, "attribute");
    const specialAttributes = parseAttributes(test, "specialAttribute");

    return [...regularAttributes, ...specialAttributes];
  }, [test]);
}

export function useSelectAttributes(attributes: Attribute[]) {
  return useMemo(() => {
    return attributes.map(attr => ({
      ...attr,
      selectOptions: attr.options.map(opt => ({ value: opt, label: opt })),
    }));
  }, [attributes]);
}
