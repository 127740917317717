import { MultiSelect } from "../../../../components/MultiSelect";
import React, { useMemo } from "react";
import { MultiValueOption } from "../../../../components/MultiSelect/types";
import { encodeSelectedValuesAsNumber } from "./utils";
import { decodeStringNumberToValuesArray } from "../../../../utils";

export type BinaryMultiSelectProps = {
  options: MultiValueOption[];
  onChange: (e: { target: { value?: string } }) => void;
  value: string;
};

export const BinaryMultiSelect = ({
  options,
  onChange,
  value,
}: BinaryMultiSelectProps) => {
  const selectedValues = useMemo(() => {
    return decodeStringNumberToValuesArray(value);
  }, [value]);
  const handleOnChange = (selectedOptions: MultiValueOption[]) => {
    const num = encodeSelectedValuesAsNumber(selectedOptions);
    const value = num ? num.toString() : "";
    onChange({ target: { value } });
  };

  return (
    <MultiSelect
      options={options}
      onChange={handleOnChange}
      selectedValues={selectedValues}
    />
  );
};
