import styled from "styled-components";

export const StyledField = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0 0 20px;
`;

export const StyledWrapper = styled.div`
  margin: 0 0 20px;
`;

export const StyledInfoIcon = styled.span`
  margin: -2px 0 0 -20px;
  font-size: 24px;
  position: absolute;
`;

export const StyledConnectedFields = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 25px 15px;
  margin-top: 20px;
`;

export const StyledSmallText = styled.div`
  font-size: 14px;
`;

export const AverageVisualisationContainer = styled.div``;

export const StyledHeading = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`;
