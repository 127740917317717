import { flatten, sortBy, groupBy, keyBy } from "lodash-es";
import { useMemo } from "react";
import { UseQueryResult } from "react-query";
import { GenericObject, HorizonMode } from "../../../../@types";
import { API_URLS } from "../../../../utils/api-constants";
import { useGet } from "../../../Tool/hooks";
import { EMPTY_ARR, EMPTY_MATRIX, HORIZON_MODE } from "../constants";
import {
  DtoEntry,
  DtoField,
  DtoTestSet,
  UseConfigResponse,
  UsEntriesResponse,
} from "../types";
import { keyByName, processMatrix } from "../utils";

export function useData(
  clientId: string,
  selectedHorizon: HorizonMode = HORIZON_MODE.short as HorizonMode
) {
  const useClientConfig = useGet(
    API_URLS.COCKPIT_CONFIG.replace("{clientId}", clientId)
  ) as UseQueryResult<UseConfigResponse>;

  const useClientEntries = useGet(
    API_URLS.COCKPIT_ENTRIES.replace("{clientId}", clientId)
  ) as UseQueryResult<UsEntriesResponse>;

  const rawFields = useClientConfig.data?.fields || EMPTY_ARR;
  const headerHtml = useClientConfig.data?.headerHtml || "";
  const clientCss = useClientConfig.data?.clientCss || "";
  const clientFavIconUrl = useClientConfig.data?.clientFavIconUrl || "";
  const rawLongMatrix = useClientConfig.data?.longMatrix || EMPTY_ARR;
  const rawShortMatrix = useClientConfig.data?.shortMatrix || EMPTY_ARR;
  const rawMatrixLabels = useClientConfig.data?.matrixLabels || EMPTY_ARR;
  const testSets = useClientConfig.data?.testSets || EMPTY_ARR;
  const settingsArray = useClientConfig.data?.settings || EMPTY_ARR;
  const labelsArray = useClientConfig.data?.labels || EMPTY_ARR;
  const entries = useClientEntries.data || EMPTY_ARR;

  const fields = useMemo<DtoField[]>(() => {
    return sortBy(rawFields, "position");
  }, [rawFields]);

  const entriesBySheetMap = useMemo(() => {
    const groupedEntries = groupBy(entries as DtoEntry[], "sheetId");

    return Object.keys(groupedEntries).reduce<
      GenericObject<Array<GenericObject>>
    >((acc, key) => {
      const keyEntries = groupedEntries[key];
      acc[key] = flatten(keyEntries.map(ke => ke.entries));
      return acc;
    }, {});
  }, [entries]);

  const testSetsBySheetMap = useMemo<GenericObject<Array<DtoTestSet>>>(() => {
    return groupBy(testSets, "spreadsheetId");
  }, [testSets]);

  const matrix = useMemo(() => {
    const rawMatrix =
      selectedHorizon === HORIZON_MODE.short ? rawShortMatrix : rawLongMatrix;
    if (!rawMatrix.length) {
      return EMPTY_MATRIX;
    }

    return processMatrix<number>(rawMatrix);
  }, [rawLongMatrix, rawShortMatrix, selectedHorizon]);

  const connectionLabels = useMemo(() => {
    if (!rawMatrixLabels.length) {
      return EMPTY_MATRIX;
    }

    return processMatrix<string>(rawMatrixLabels);
  }, [rawMatrixLabels]);

  const settings = keyBy<string>(settingsArray, "name");
  const labels = keyByName(labelsArray);

  return {
    isLoading: useClientConfig.isLoading || useClientEntries.isLoading,
    fields,
    matrix,
    connectionLabels,
    entries,
    entriesBySheetMap,
    testSetsBySheetMap,
    testSets,
    settings,
    labels,
    headerHtml,
    clientCss,
    clientFavIconUrl,
  };
}
