import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import { REPORT_URL } from "../constants";
import { useDelete, useGetList } from "../hooks";

function ReportList() {
  const history = useHistory();
  const { t } = useTranslation();
  const getReports = useGetList(API_URLS.REPORTS);
  const deleteReport = useDelete(API_URLS.REPORTS);
  const confirm = useConfirm();

  return (
    <Container maxWidth={false} className="list-page">
      {(getReports.isLoading || deleteReport.isLoading) && <Loader />}
      {getReports.error && (
        <Alert severity="error">
          {t("tool.general.error.dataFetching", "Data fetching error.")}
        </Alert>
      )}
      <TableContainer component={Paper} className="table-container">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("tool.reportsList.id", "Name")}</TableCell>
              <TableCell>{t("tool.reportsList.testName", "Test")}</TableCell>
              <TableCell align="right">
                {t("tool.reportsList.from", "From")}
              </TableCell>
              <TableCell align="right">
                {t("tool.reportsList.to", "To")}
              </TableCell>
              <TableCell align="right">
                {t("tool.reportsList.started", "Started")}
              </TableCell>
              <TableCell align="right">
                {t("tool.reportsList.finished", "Finished")}
              </TableCell>
              <TableCell align="right">
                {t("tool.reportsList.actions", "Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getReports?.data?.map(report => (
              <TableRow key={report._id}>
                <TableCell component="th" scope="row">
                  {report.name}
                </TableCell>
                <TableCell align="left">{report.testName}</TableCell>
                <TableCell align="right">
                  {report.startDate &&
                    new Date(report.startDate).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  {report.endDate &&
                    new Date(report.endDate).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  {report.startedTestFlowsCount}
                </TableCell>
                <TableCell align="right">
                  {report.completedTestFlowsCount}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => {
                      history.push(`${REPORT_URL}/${report._id}`);
                    }}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => {
                      confirm({
                        title: t(
                          "tool.reportsList.confirmDeletionTitle",
                          "Remove Confirmation"
                        ),
                        description: t(
                          "tool.reportsList.confirmDeletionDesc",
                          "Are you sure you want to delete {{name}} ?",
                          { name: report.name }
                        ),
                      })
                        .then(() => deleteReport.mutateAsync(report._id))
                        .catch(() => {});
                    }}
                    size="large"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default ReportList;
