import { Box, Container } from "@mui/material";
import React from "react";

function PageWrapper(props) {
  return (
    <Container maxWidth={false}>
      <Box p="24px 0 24px" {...props} />
    </Container>
  );
}

export default PageWrapper;
