import PropTypes from "prop-types";
import React from "react";
import Typography from "../typography";
import Icon from "./icon";
import "./styles.scss";

function Radio(props) {
  const { label, ...rest } = props;
  return (
    <label className="tg_radio">
      <input type="radio" {...rest} />
      <div className="tg_icon">
        <Icon />
      </div>
      {label && (
        <Typography h3 className="tg_label">
          {label}
        </Typography>
      )}
    </label>
  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Radio;
