import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./styles.scss";

function Button(props) {
  const { large, ...rest } = props;
  return (
    <button
      className={classNames("tg_button", {
        "tg_btn-large": large,
      })}
      {...rest}
    />
  );
}

Button.propTypes = {
  large: PropTypes.bool,
};

Button.defaultProps = {
  large: false,
};

export default Button;
