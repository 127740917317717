import { useLayoutEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import Tag from "../Tag";
import { TagRef, TagsProps } from "./types";

function Tags({
  tags,
  onRefsCreated,
  isTooltipEnabled,
  isTagClickEnabled,
  onTagClicked,
}: TagsProps) {
  const tagsRef = useRef<TagRef[]>([]);

  useLayoutEffect(() => {
    tagsRef.current = tagsRef.current.slice(0, tags.length);
    onRefsCreated(tagsRef.current);
    ReactTooltip.rebuild();
  }, [tags, onRefsCreated]);

  return (
    <>
      {tags.map((tagProps, index) => (
        <Tag
          {...tagProps}
          isTagClickEnabled={isTagClickEnabled}
          isTooltipEnabled={isTooltipEnabled}
          key={`${tagProps.field.quadrant}-${index}`}
          onClick={() => {
            if (isTagClickEnabled) {
              onTagClicked(tagProps.field.id);
            }
          }}
          ref={(el: HTMLOrSVGElement) => {
            return (tagsRef.current[index] = {
              node: el,
              props: tagProps,
            });
          }}
        />
      ))}
    </>
  );
}
export default Tags;
