import React from "react";
import { useLocation } from "react-router-dom";
import { API_URLS } from "../../../utils/api-constants";
import { usePost } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import ReportForm from "./Form";

function AddReport() {
  const location = useLocation();
  const testId = new URLSearchParams(location.search).get("testId");
  const createReport = usePost(API_URLS.REPORTS);

  return (
    <PageWrapper>
      <ReportForm
        testId={testId}
        report={createReport.data}
        loading={createReport.isLoading}
        error={createReport.error}
        onSubmit={createReport.mutateAsync}
      />
    </PageWrapper>
  );
}

export default AddReport;
