import React from "react";
import "./styles.scss";

function TextField(props) {
  const { textarea, ...rest } = props;
  const Component = textarea ? "textarea" : "input";

  return <Component {...rest} className="tg_text-field" />;
}

export default TextField;
