import classNames from "classnames";
import { omit } from "lodash-es";
import React from "react";

const hTagRegex = /h{1}[1-6]/gm;

function Typography(props) {
  const heading = Object.keys(props).find(k => k.match(hTagRegex));
  const componentProps = omit(props, [heading]);
  const {
    className,
    as: Component = heading || "div",
    center,
    ...rest
  } = componentProps;

  return (
    <Component
      className={classNames(
        {
          [`tg_${heading || "body"}`]: true,
          tg_center: center,
        },
        className
      )}
      {...rest}
    />
  );
}

export default Typography;
