import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import { useGet, usePut } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import ClientForm from "./Form";

function EditClient() {
  const { id } = useParams();
  const getClient = useGet(`${API_URLS.CLIENTS}/${id}`);
  const updateClient = usePut(`${API_URLS.CLIENTS}/${id}`);

  if (getClient.isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <ClientForm
        client={getClient.data}
        error={updateClient.error || getClient.error}
        loading={updateClient.isLoading || getClient.isLoading}
        onSubmit={updateClient.mutateAsync}
        editMode
      />
    </PageWrapper>
  );
}

export default EditClient;
