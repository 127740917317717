import { ClickableText } from "./styled";

function Short({ onClick }: any) {
  return (
    <g id="_1_state" data-name="1 state" transform="translate(-13)">
      <g
        id="Rectangle_415"
        data-name="Rectangle 415"
        transform="translate(661 986)"
        fill="#fff"
        stroke="#7f7f7f"
        strokeWidth="1"
      >
        <rect width="294" height="40" rx="20" stroke="none" />
        <rect x="0.5" y="0.5" width="293" height="39" rx="19.5" fill="none" />
      </g>
      <g
        id="Rectangle_416"
        data-name="Rectangle 416"
        transform="translate(659 986)"
        fill="#ccc"
        stroke="#7f7f7f"
        strokeWidth="3"
      >
        <rect width="155" height="40" rx="20" stroke="none" />
        <rect x="1.5" y="1.5" width="152" height="37" rx="18.5" fill="none" />
      </g>
      <text
        id="Performanz"
        transform="translate(796 1012)"
        fill="rgba(0,0,0,0.5)"
        fontSize="18"
        fontFamily="Roboto-Bold, Roboto"
        fontWeight="700"
      >
        <tspan x="-118.652" y="0">
          PERFORMANZ
        </tspan>
      </text>
      <ClickableText
        onClick={onClick}
        id="Innovation"
        transform="translate(832 1012)"
        fill="rgba(0,0,0,0.5)"
        fontSize="18"
        fontFamily="Roboto-Regular, Roboto"
      >
        <tspan x="0" y="0">
          INNOVATION
        </tspan>
      </ClickableText>
    </g>
  );
}
export default Short;
