import { TRAFFIC_LIGHTS } from "../../constants";
import { TrafficLightsProps } from "../../types";

export const ResultChart = ({ trafficLights }: TrafficLightsProps) => {
  return (
    <div className="chart-item">
      <div className="chart-item-title">Ergebnisse</div>
      <div className="traffic-light-container">
        {trafficLights.map((tl, i) => (
          <img
            key={i}
            className="traffic-light"
            src={TRAFFIC_LIGHTS[tl]}
            alt=""
          />
        ))}
      </div>
    </div>
  );
};
