import { DtoTestSet, DtoTestSetItem, StatisticItem } from "../types";
import { useMemo } from "react";
import { groupBy, keyBy } from "lodash-es";
import { EMPTY_ARR } from "../constants";
import { TEST_TYPES } from "../../constants";
import { createEmptyArray, getIndex } from "../utils";
import { GenericObject } from "../../../../@types";
import { round } from "lodash-es";
import { decodeStringNumberToValuesArray } from "../../utils";

type GroupedEntries = GenericObject<GenericObject<number>>;

function getValueKeys(value: string, type: string): string[] {
  return type !== TEST_TYPES.MULTI_CHOICE
    ? [value]
    : decodeStringNumberToValuesArray(value);
}

function getRepliesGroupedByQuestion(
  entries: Array<GenericObject>,
  questions: DtoTestSetItem[]
): GroupedEntries {
  return entries.reduce<GroupedEntries>((entriesAcc, entry) => {
    questions.forEach(({ dbColumn, type }) => {
      const valueKeys = getValueKeys(entry[dbColumn], type); //array is needed for multichoice type because of binary encoding

      if (!entriesAcc[dbColumn]) {
        entriesAcc[dbColumn] = {};
      }

      valueKeys.forEach(valueKey => {
        if (!entriesAcc[dbColumn][valueKey]) {
          entriesAcc[dbColumn][valueKey] = 1;
        } else {
          entriesAcc[dbColumn][valueKey] += 1;
        }
      });

      if (!entriesAcc[dbColumn]["sum"]) {
        entriesAcc[dbColumn]["sum"] = 0;
      }

      entriesAcc[dbColumn]["sum"] += 1;
    });
    return entriesAcc;
  }, {});
}

function getQuestionsForTestSet(
  testSet: DtoTestSet,
  testSetEntries: Array<GenericObject>
) {
  const filteredQuestions = testSet.items.filter(
    question =>
      question.type === TEST_TYPES.LIKERT ||
      question.type === TEST_TYPES.CHOICE ||
      question.type === TEST_TYPES.MULTI_CHOICE
  );
  const repliesGrouped = getRepliesGroupedByQuestion(
    testSetEntries,
    filteredQuestions
  );

  return filteredQuestions.map((question, qId) => {
    const questionReply: GenericObject<number> =
      repliesGrouped[question.dbColumn];
    const choices = createEmptyArray(question.values).map(
      (dummy: undefined, index) => {
        const replyIndex = getIndex(index);
        const questionIndexByType =
          question.type === TEST_TYPES.LIKERT ? index + 1 : index;
        const count = questionReply[questionIndexByType] || 0;
        const percentage = round((count / questionReply.sum) * 100, 2);

        return {
          id: `choice-${index.toString()}`,
          title: question[`v${replyIndex}`],
          count,
          percentage,
          replyIndex: parseInt(replyIndex, 10),
        };
      }
    );

    const averageDistribution = choices.reduce((acc, choice) => {
      return acc + choice.count * choice.replyIndex;
    }, 0);

    const totalChoicesSelected = choices.reduce((acc, choice) => {
      return acc + choice.count;
    }, 0);

    const average = round(averageDistribution / questionReply.sum, 2);

    return {
      id: `question-${qId.toString()}`,
      title: question.text,
      choices,
      average,
      totalReplies: questionReply.sum,
      totalChoicesSelected,
      type: question.type,
    } as StatisticItem;
  });
}

export function useStatisticData(
  testSets: Array<DtoTestSet>,
  entries: Array<GenericObject>
) {
  const questions = useMemo<StatisticItem[]>(() => {
    if (testSets.length && entries.length) {
      const entriesByTestSet = groupBy(entries, "testSetId");
      const testSetsMap = keyBy(testSets, "_id");

      return Object.keys(testSetsMap).reduce<StatisticItem[]>((acc, tsId) => {
        const questionsForTestSet = getQuestionsForTestSet(
          testSetsMap[tsId],
          entriesByTestSet[tsId]
        );
        return [...acc, ...questionsForTestSet];
      }, []);
    }

    return EMPTY_ARR;
  }, [testSets, entries]);

  return questions;
}
