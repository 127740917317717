import JsFileDownloader from "js-file-downloader";
import { identity, pickBy } from "lodash-es";

export const downloadCsv = (queryParams = {}, filename = "entries.csv") => {
  const queryString = new URLSearchParams(
    pickBy(queryParams, identity)
  ).toString();
  new JsFileDownloader({
    url: `${process.env.REACT_APP_API_URL}/export/entries?${queryString}`,
    filename: "entries.csv",
  });
};
