import PropTypes from "prop-types";
import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";

const initialState = {
  isAuthenticated: !!localStorage.getItem("adminToken"),
  publicTest: {},
  selectedClientId: undefined,
  testSessionData: { sessionId: localStorage.getItem("sessionId") },
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.element.isRequired,
};

export const Context = createContext(initialState);

export default Store;
