import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { FastField, Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import { getCurrentUrl } from "../../../utils/urls";
import {
  PUBLIC_REPORT_URL,
  CUSTOM_PUBLIC_REPORT_URL,
} from "../../Frontend/constants";
import { REPORT_URL } from "../constants";

function ReportForm({ testId, report, onSubmit, error, loading, editMode }) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        testId,
        name: "",
        welcomeHtml: "",
        averageLabel: t("tool.reportForm.averageDefaultText", "Average"),
        distributionLabel: t(
          "tool.reportForm.distributionDefaultText",
          "Distribution"
        ),
        expandItemsLabel: t(
          "tool.reportForm.expandItemsLabel",
          "Show item statistics"
        ),
        startDate: "",
        endDate: "",
        cssLink: "",
        onlyCompleted: false,
        ...(report &&
          Object.keys(report).reduce((acc, key) => {
            let value = report[key];
            if (key === "startDate" || key === "endDate") {
              if (value) {
                value = format(new Date(report[key]), "yyyy-MM-dd");
              } else {
                value = ""; // Use empty string for empty field.
              }
            }

            return {
              ...acc,
              [key]: value,
            };
          }, {})),
      }}
      onSubmit={async values => {
        const res = await onSubmit(values);

        if (res) {
          history.push(`${REPORT_URL}/${res._id}`);
        }
      }}
    >
      {({ touched, errors, values }) => {
        return (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <FastField
                      name="name"
                      as={TextField}
                      required
                      label={t(
                        "tool.reportForm.testName",
                        "Name / Description"
                      )}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      name="welcomeHtml"
                      label={t("tool.reportForm.welcomeHTML", "Welcome HTML")}
                      as={TextField}
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {t("tool.reportForm.averageLabel", "Average")}*
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FastField
                      name="averageLabel"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {t("tool.reportForm.distributionLabel", "Distribution")}*
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FastField
                      name="distributionLabel"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {t("tool.reportForm.showItems", "Show items")}*
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FastField
                      name="expandItemsLabel"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3}>
                    <Typography>
                      {t("tool.reportForm.from", "From")}:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Field
                      name="startDate"
                      type="date"
                      as={TextField}
                      error={touched.startDate && Boolean(errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: { minWidth: 175 },
                        max: values.endDate ? values.endDate : undefined,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3}>
                    <Typography>{t("tool.reportForm.to", "To")}:</Typography>
                  </Grid>
                  <Grid item xs>
                    <Field
                      name="endDate"
                      error={touched.endDate && Boolean(errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      min={values.startDate ? values.startDate : undefined}
                      type="date"
                      as={TextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: { minWidth: 175 },
                        min: values.startDate ? values.startDate : undefined,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={t(
                        "tool.reportForm.onlyCompletedTest",
                        "Only completed tests"
                      )}
                      control={
                        <FastField
                          type="checkbox"
                          as={Checkbox}
                          name="onlyCompleted"
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>CSS</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <FastField
                      name="cssLink"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box m="20px 0 15px">
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        {editMode
                          ? t("tool.addEdit.save", "Save")
                          : t("tool.addEdit.create", "Create")}
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          history.push(REPORT_URL);
                        }}
                      >
                        {t("tool.reportForm.close", "Close")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                {error && (
                  <Typography color="secondary">
                    {error?.response?.data?.message ??
                      t(
                        "tool.addEdit.unexpectedError",
                        "Unexpected error occurred"
                      )}
                  </Typography>
                )}
                {loading && <Loader />}
                {report && (
                  <>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={3}>
                        <Typography>ID</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          variant="outlined"
                          defaultValue={report._id}
                          fullWidth
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {t("tool.reportForm.reportLink", "Report Link")}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          disabled
                          defaultValue={getCurrentUrl(
                            `${
                              report.client?.isSpecialReport
                                ? CUSTOM_PUBLIC_REPORT_URL
                                : PUBLIC_REPORT_URL
                            }/${report._id}`
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ReportForm;
