import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import { useGet, usePut } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import ReportForm from "./Form";

function EditReport() {
  const { id } = useParams();
  const getReport = useGet(`${API_URLS.REPORTS}/${id}`);
  const updateReport = usePut(`${API_URLS.REPORTS}/${id}`);

  if (getReport.isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <ReportForm
        report={getReport.data}
        error={updateReport.error || getReport.error}
        loading={updateReport.isLoading || getReport.isLoading}
        onSubmit={updateReport.mutateAsync}
        editMode
      />
    </PageWrapper>
  );
}

export default EditReport;
