import React from "react";
import { API_URLS } from "../../../utils/api-constants";
import { usePost } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import TestForm from "./Form";

function AddTest() {
  const createTest = usePost(API_URLS.TESTS);

  return (
    <PageWrapper>
      <TestForm
        loading={createTest.isLoading}
        error={createTest.error}
        onSubmit={createTest.mutateAsync}
      />
    </PageWrapper>
  );
}

export default AddTest;
