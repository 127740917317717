import { useCallback, useRef, useState } from "react";
import { HorizonMode } from "../../../../@types";
import Background from "./components/Background";
import HorizonSelector from "./components/HorizonSelector";
import CurrentTag from "./components/Tag/CurrentTag";
import Tags from "./components/Tags";
import { TagRef } from "./components/Tags/types";
import {
  LEADER_LINES_CONTAINER_ID,
  useLeaderLines,
} from "./hooks/useLeaderLines";
import { RadarProps } from "./types";
import { createTag, createTags } from "./utils";

function Radar({
  fields,
  fieldsMap,
  onTagClicked,
  mode,
  onModeChanged,
  currentField,
  settings,
}: RadarProps) {
  const [tagsRefs, setTagsRefs] = useState<TagRef[]>([]);
  const [refsCreated, setRefsCreated] = useState<boolean>(false);
  // const showOnlyImportantConnections = useReactiveVar(
  //   showOnlyImportantConnectionsVar
  // );
  const currentFieldRef = useRef<HTMLOrSVGElement>(null);

  useLeaderLines(
    false,
    fields,
    fieldsMap,
    tagsRefs,
    currentFieldRef,
    currentField
  );

  const tags = createTags(fields, currentField);

  const handleRefsCreated = useCallback(
    (refs: TagRef[]) => {
      if (refs.length && !refsCreated) {
        setTagsRefs(refs);
        setRefsCreated(true);
      }
    },
    [setTagsRefs, refsCreated]
  );

  return (
    <div
      style={{
        height: "100%",
        width: "auto",
        position: "relative",
      }}
    >
      <Background settings={settings} />
      <div id={LEADER_LINES_CONTAINER_ID} />
      <svg
        viewBox="0 0 1150 1080"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      >
        <Tags
          isTooltipEnabled={true}
          isTagClickEnabled={true}
          tags={tags}
          onRefsCreated={handleRefsCreated}
          onTagClicked={onTagClicked}
        />
        {currentField && (
          <CurrentTag
            ref={currentFieldRef}
            {...createTag(currentField, { x: 50, y: 50 })}
          />
        )}
        <HorizonSelector
          mode={mode as HorizonMode}
          onModeChanged={onModeChanged}
        />
      </svg>
    </div>
  );
}

export default Radar;
