import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Loader from "./components/Loader";
import LayoutRouter from "./routes/";
import Store from "./store/store";

const queryClient = new QueryClient();

function App() {
  return (
    <Store>
      <Suspense fallback={<Loader />}>
        <QueryClientProvider client={queryClient}>
          <LayoutRouter />
        </QueryClientProvider>
      </Suspense>
    </Store>
  );
}

export default App;
