export const tryEmbedCssLink = (cssLink, onLoad) => {
  if (cssLink) {
    const dynamicStylesLink = document.createElement("link");
    dynamicStylesLink.rel = "stylesheet";
    dynamicStylesLink.type = "text/css";
    dynamicStylesLink.href = cssLink;
    dynamicStylesLink.addEventListener("load", onLoad);
    document.getElementsByTagName("body")[0].appendChild(dynamicStylesLink);
  }
};

export const tryEmbedScript = (jsLink, onLoad) => {
  if (jsLink) {
    const dynamicScriptLink = document.createElement("script");
    dynamicScriptLink.src = jsLink;
    dynamicScriptLink.addEventListener("load", onLoad);
    document.getElementsByTagName("body")[0].appendChild(dynamicScriptLink);
  }
};

export const setFavIcon = icon => {
  document.getElementById("fav-icon").href = icon;
};
