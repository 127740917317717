import classNames from "classnames";
import { ReportProps } from "../types";

export const ReportHeader = ({ data, isIndividual = false }: ReportProps) => {
  return (
    <div
      className={classNames("report-header-wrap", {
        "is-individual": isIndividual,
      })}
    >
      <div className="icon">
        <img alt="" />
      </div>
      <p className="name">{data.testName}</p>
      <h1>{isIndividual ? "Ihre" : "Summarische"} Auswertung</h1>
      {!isIndividual && (
        <p>
          für <span className="highlight-number">{data.testFlowsCount}</span>{" "}
          Teilnehmende
        </p>
      )}
    </div>
  );
};
