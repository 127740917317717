import React, { useContext, useEffect, useState } from "react";
import { UPDATE_CURRENT_SESSION_TEST_DATA } from "../../../../../store/actions";
import { Context } from "../../../../../store/store";
import { useHydratedData } from "../../../CustomReports/hooks/useHydratedData";
import { Report } from "../../../CustomReports/components/Report";
import {
  tryEmbedCssLink,
  tryEmbedScript,
} from "../../../../../utils/embed-utils";
import { insertVariablesIntoHtml } from "../TestResults/utils";

function CustomTestResults() {
  const [state, dispatch] = useContext(Context);
  const [, setCustomFuncLoaded] = useState(false);

  const {
    publicTest: {
      sets,
      entries,
      customReportUrl,
      name,
      finalHtml,
      client,
      completedTestFlowsCount,
    },
  } = state;

  const isSpecialReport = !!client?.isSpecialReport;
  const disableIndividualReport = !!client?.disableIndividualReport;
  const reportCssUrl = client?.reportCssUrl;

  const hydratedData = useHydratedData(
    {
      test: {
        name,
        completedTestFlowsCount,
      },
      testSets: sets,
      entries,
    },
    true
  );

  useEffect(() => {
    if (isSpecialReport) {
      tryEmbedScript(customReportUrl, () => {
        setCustomFuncLoaded(true);
      });

      tryEmbedCssLink(reportCssUrl);
    }
  }, [customReportUrl, isSpecialReport, reportCssUrl]);

  useEffect(() => {
    dispatch({
      type: UPDATE_CURRENT_SESSION_TEST_DATA,
      payload: { sessionId: null },
    });
  }, [dispatch]);

  return (
    <div className="tg_custom-test-results-page">
      {!disableIndividualReport ? (
        <Report data={hydratedData} isIndividual={true} />
      ) : (
        <div className="footer">
          <div
            className="tg_info"
            dangerouslySetInnerHTML={{
              __html: insertVariablesIntoHtml(finalHtml, entries),
            }}
          />
        </div>
      )}
    </div>
  );
}

export default CustomTestResults;
