import { GenericObject } from "../../@types";
import { Attribute } from "./components/AttributeFilters/types";

export const filterEntries = (
  entries: GenericObject[],
  attributes: GenericObject<Attribute>
) => {
  const attributesKeys = Object.keys(attributes);

  if (!attributesKeys.length) {
    return entries;
  }

  return entries.filter(entry => {
    return attributesKeys.every(attrKey => {
      const attribute = attributes[attrKey];
      return attribute.options.some(
        attrOption => entry[attrKey] === attrOption
      );
    });
  });
};

export const decodeStringNumberToValuesArray = (num: string) => {
  const selectedValues: any[] = [];
  const binString = parseInt(num, 10).toString(2);

  const allValues = binString.split("").reverse();
  allValues.forEach((v, index) => {
    if (v === "1") {
      selectedValues.push(index);
    }
  });

  return selectedValues;
};
