import { Assessment, Group } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ListIcon from "@mui/icons-material/List";
import {
  Button,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { useDelete, useGetList } from "../hooks";
import { API_URLS } from "./../../../utils/api-constants";
import {
  ADD_GROUP_URL,
  ADD_REPORT_URL,
  ADD_TEST_URL,
  ENTRIES_URL,
  TESTS_URL,
} from "./../constants";
import { axiosPost } from "../../../utils/queries";

function TestsList() {
  const { t } = useTranslation();
  const history = useHistory();
  const confirm = useConfirm();
  const getTests = useGetList(API_URLS.TESTS);
  const deleteTest = useDelete(API_URLS.TESTS);

  function onAddReportByTestButtonClick(test) {
    history.push(`${ADD_REPORT_URL}/?testId=${test._id}`);
  }

  function onAddGroupByTestButtonClick(test) {
    history.push(`${ADD_GROUP_URL}/?testId=${test._id}`);
  }

  function onAddTestButtonClick() {
    history.push(ADD_TEST_URL);
  }

  function onEditTestButtonClick(test) {
    history.push(`${TESTS_URL}/${test._id}`);
  }

  function onDuplicateButtonClick(test) {
    history.push(`${TESTS_URL}/${test._id}/duplicate`);
  }

  function onShowTestEntriesButtonClick(test) {
    history.push(`${ENTRIES_URL}/?testId=${test._id}`);
  }

  function onDeleteEntriesButtonClick(test) {
    confirm({
      title: t("tool.tests.confirmDeletionTitle", "Remove Confirmation"),
      description: t(
        "tool.tests.confirmEntriesDeletionDesc",
        "Are you sure you want to delete all entries of {{name}} ?",
        { name: test.name }
      ),
    })
      .then(async () => {
        await axiosPost(`${API_URLS.TESTS}/${test._id}/clearAllReplies`);
        await getTests.refetch();
        toast.success(
          t("tool.tests.allTestEntriesDeleted", "All test entries deleted")
        );
      })
      .catch(e => {
        toast.error(e.message);
      });
  }

  function onDeleteTestButtonClick(test) {
    confirm({
      title: t("tool.tests.confirmDeletionTitle", "Remove Confirmation"),
      description: t(
        "tool.tests.confirmDeletionDesc",
        "Are you sure you want to delete {{name}} ?",
        { name: test.name }
      ),
    })
      .then(() => deleteTest.mutate(test._id))
      .catch(() => {});
  }

  return (
    <Container maxWidth={false} className="list-page">
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        onClick={onAddTestButtonClick}
      >
        {t("tool.tests.newTest", "New Test")}
      </Button>
      {(getTests.isLoading || deleteTest.isLoading) && <Loader />}
      {getTests.error && (
        <Alert severity="error">
          {t("tool.general.error.dataFetching", "Data fetching error.")}
        </Alert>
      )}
      <TableContainer component={Paper} className="table-container">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">{t("tool.tests.id", "ID")}</TableCell>
              <TableCell align="right">
                {t("tool.tests.participants", "Participants")}
              </TableCell>
              <TableCell align="right">
                {t("tool.tests.created", "Created")}
              </TableCell>
              <TableCell align="right">
                {t("tool.tests.actions", "Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getTests?.data?.map(test => (
              <TableRow key={test._id}>
                <TableCell component="th" scope="row">
                  {test.name}
                </TableCell>
                <TableCell align="right">{test._id}</TableCell>
                <TableCell align="right">
                  {test.completedTestFlowsCount || 0} /{" "}
                  {test.maxReplies || t("tool.tests.unlimited", "unlimited")}
                </TableCell>
                <TableCell align="right">
                  {new Date(test.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onAddGroupByTestButtonClick(test)}
                    title={t("tool.tests.addGroup", "Add Group by Test")}
                    size="large"
                  >
                    <Group />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onAddReportByTestButtonClick(test)}
                    title={t("tool.tests.addReport", "Add Report by Test")}
                    size="large"
                  >
                    <Assessment />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onShowTestEntriesButtonClick(test)}
                    title={t("tool.tests.showTestEntries", "Show Test Entries")}
                    size="large"
                  >
                    <ListIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onEditTestButtonClick(test)}
                    title={t("tool.tests.editTest", "Edit Test")}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onDuplicateButtonClick(test)}
                    title={t("tool.tests.duplicateTest", "Duplicate Test")}
                    size="large"
                  >
                    <FileCopyIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onDeleteEntriesButtonClick(test)}
                    title={t(
                      "tool.tests.deleteAllEntries",
                      "Delete All Entries"
                    )}
                    size="large"
                  >
                    <DeleteSweepIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onDeleteTestButtonClick(test)}
                    title={t("tool.tests.deleteTest", "Delete Test")}
                    size="large"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default TestsList;
