import { Accordion } from "react-bootstrap";
import { ReportProps } from "../../types";
import { AccordionGroupItem } from "./AccordionGroupItem";
import { AccordionIndividualItem } from "./AccordionIndividualItem";

export const AccordionItems = ({ data, isIndividual = false }: ReportProps) => {
  const ItemComponent = isIndividual
    ? AccordionIndividualItem
    : AccordionGroupItem;

  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>
        <img className="icon-list" style={{ marginRight: "21px" }} alt="" />
        {isIndividual
          ? "Items – Ihre Einschätzung"
          : "Items – Häufigkeiten und Mittelwerte"}
      </Accordion.Header>
      <Accordion.Body>
        <ul className="list-2">
          <li>
            <div className="custom-grid custom-grid-data-0">
              {data.questionsData.choices.map((c, i) => (
                <div className={`column-${i + 1}`} key={c.key}>
                  <span className="legende">{c.name}</span>
                </div>
              ))}
            </div>
          </li>
          {data.questionsData.questions.map((q, qIndex) => (
            <ItemComponent question={q} key={qIndex} />
          ))}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
};
