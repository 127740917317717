import { Container, CssBaseline } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import React, { useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Context } from "../../store/store";
import { LOGIN_URL } from "./constants";
import Login from "./Login";
import ProtectedArea from "./ProtectedArea";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#1976d2",
      dark: "#115293",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

function Tool() {
  const [state] = useContext(Context);
  const location = useLocation();

  if (!state.isAuthenticated && location.pathname !== LOGIN_URL) {
    return <Redirect to="/tool/login" />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <Container
            component="main"
            className="tool-main-container"
            disableGutters
            maxWidth={false}
          >
            <Switch>
              <Route exact path="/tool/login" component={Login} />
              <Route path="/tool" component={ProtectedArea} />
            </Switch>
          </Container>
        </React.Fragment>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Tool;
