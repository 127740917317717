import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import { useGet, usePut } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import GroupForm from "./Form";

function EditGroup() {
  const { id } = useParams();
  const getGroup = useGet(`${API_URLS.GROUPS}/${id}`);
  const updateGroup = usePut(`${API_URLS.GROUPS}/${id}`);

  if (getGroup.isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <GroupForm
        group={getGroup.data}
        error={updateGroup.error || getGroup.error}
        loading={updateGroup.isLoading || getGroup.isLoading}
        onSubmit={updateGroup.mutateAsync}
        editMode
      />
    </PageWrapper>
  );
}

export default EditGroup;
