import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  REPLACE_TEST_ENTRIES,
  SET_SELECTED_CLIENT_ID,
  TEST_LOAD_SUCCESS,
  UPDATE_CURRENT_SESSION_TEST_DATA,
  UPDATE_TEST_ENTRIES_SUCCESS,
} from "./actions";
import { mergeEntries } from "./utils";

export default function Reducer(state, action = {}) {
  const { type, payload } = action;
  switch (type) {
    // Auth check
    case LOGIN_SUCCESS:
      const { token } = payload;
      if (token) {
        localStorage.setItem("adminToken", token);
      }
      return {
        ...state,
        isAuthenticated: true,
      };
    case LOGOUT_REQUEST:
      localStorage.removeItem("adminToken");
      return {
        isAuthenticated: false,
      };
    case TEST_LOAD_SUCCESS:
      return {
        ...state,
        publicTest: payload,
      };
    case REPLACE_TEST_ENTRIES:
      return {
        ...state,
        publicTest: { ...state.publicTest, entries: payload },
      };
    case UPDATE_TEST_ENTRIES_SUCCESS:
      return {
        ...state,
        publicTest: {
          ...state.publicTest,
          entries: mergeEntries(payload, state.publicTest.entries),
        },
      };
    case UPDATE_CURRENT_SESSION_TEST_DATA:
      const { testSessionData } = state;
      localStorage.setItem("sessionId", payload.sessionId);

      return {
        ...state,
        testSessionData: { ...testSessionData, ...payload },
      };
    case SET_SELECTED_CLIENT_ID:
      return {
        ...state,
        selectedClientId: payload,
      };
    default:
      return state;
  }
}
