import { useStatisticData } from "../../hooks/useStatisticData";
import { DtoTestSet } from "../../types";
import { GenericObject } from "../../../../../@types";
import {
  QuestionsContainer,
  Question,
  QuestionTitle,
  ChoicesContainer,
  Choice,
  ChoiceTitle,
  ChoiceNumber,
  AverageContainer,
  AverageLabel,
} from "./styled";
import { TEST_TYPES } from "../../../constants";

type StatisticProps = {
  testSets: Array<DtoTestSet>;
  entries: Array<GenericObject>;
};

function Statistic({ testSets, entries }: StatisticProps) {
  const questions = useStatisticData(testSets, entries);

  return (
    <QuestionsContainer className="questions-container">
      {questions.map(q => (
        <Question key={q.id} className="single-question">
          <QuestionTitle className="question-title">{q.title}</QuestionTitle>
          <ChoicesContainer className="choices-container">
            {q.choices.map(ch => (
              <Choice key={ch.id} className="single-choice">
                <ChoiceTitle className="choice-title">{ch.title}</ChoiceTitle>
                <ChoiceNumber className="choice-number">
                  {ch.count.toLocaleString()}
                </ChoiceNumber>
                <ChoiceNumber className="choice-number">
                  {ch.percentage.toLocaleString()}%
                </ChoiceNumber>
              </Choice>
            ))}
          </ChoicesContainer>
          <AverageContainer className="sum-container">
            <AverageLabel className="sum-label">∑</AverageLabel>
            <ChoiceNumber className="sum-number">
              {q.totalChoicesSelected.toLocaleString()}
              {q.type === TEST_TYPES.MULTI_CHOICE && (
                <>&nbsp;({q.totalReplies.toLocaleString()})</>
              )}
            </ChoiceNumber>
            <ChoiceNumber />
          </AverageContainer>
          {q.type === TEST_TYPES.LIKERT && (
            <AverageContainer className="average-container">
              <AverageLabel className="average-label">Ø</AverageLabel>
              <ChoiceNumber className="choice-number">
                {q.average.toLocaleString()}
              </ChoiceNumber>
              <ChoiceNumber />
            </AverageContainer>
          )}
        </Question>
      ))}
    </QuestionsContainer>
  );
}

export default Statistic;
