import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { TEST_TYPES } from "../../../../constants";
import { Radio, Select, TextField, Typography } from "../../../components";
import { BinaryMultiSelect } from "./BinaryMultiSelect";
import "./styles.scss";

function TestItem(props) {
  const { item, onChange, initialValue, ...rest } = props;
  const [value, setValue] = useState(initialValue);

  const selectOptions = useMemo(() => {
    return [...Array(item.values)].map((_, i) => ({
      value: i,
      label: item[`v${i + 1}`],
    }));
  }, [item]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const debounceCallback = useMemo(
    () => debounce((data, isTextInput) => onChange(data, isTextInput), 100),
    [onChange]
  );

  function updateValueState(e) {
    setValue(e.target.value);
    return { [`${item.dbColumn}`]: e.target.value };
  }

  function onValueChange(e) {
    const data = updateValueState(e);
    debounceCallback(data);
  }

  function onTextInputValueChange(e) {
    const data = updateValueState(e);
    debounceCallback(data, true);
  }

  return (
    <div {...rest}>
      {item.type === TEST_TYPES.SUB_HEADING ? (
        <div
          className="tg_info tg_test-sub-heading-html"
          dangerouslySetInnerHTML={{ __html: item.text }}
        ></div>
      ) : (
        <>
          <Typography h1>{item.text}</Typography>
          {item.type === TEST_TYPES.LIKERT && (
            <div className="tg_radio-group-wrapper">
              <Typography h3 className="tg_group-title">
                {item[`v${1}`]}
              </Typography>
              <div className="tg_radio-group">
                {[...Array(item.values)].map((_, i) => (
                  <Radio
                    key={i}
                    name={`radio-${item._id}`}
                    checked={value === (i + 1).toString()}
                    onChange={onValueChange}
                    label={item[`v${i + 1}`]}
                    value={i + 1}
                  />
                ))}
              </div>
              <Typography h3 className="tg_group-title">
                {item[`v${item.values}`]}
              </Typography>
            </div>
          )}
          {item.type === TEST_TYPES.CHOICE && (
            <Select
              name={`select-${item._id}`}
              value={value}
              onChange={onValueChange}
              options={selectOptions}
            />
          )}
          {item.type === TEST_TYPES.NUMERICAL && (
            <TextField
              name={`number-${item._id}`}
              type="number"
              value={value}
              onChange={onValueChange}
            />
          )}
          {item.type === TEST_TYPES.TEXT && (
            <TextField
              name={`textarea-${item._id}`}
              textarea
              rows="6"
              value={value}
              onChange={onTextInputValueChange}
            />
          )}
          {item.type === TEST_TYPES.MULTI_CHOICE && (
            <div className="tg_multi-select-wrapper">
              <BinaryMultiSelect
                options={selectOptions}
                onChange={onValueChange}
                value={value}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TestItem;
