import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { FastField, Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Loader from "../../../components/Loader";
import { isLinkRegex } from "../../../utils/regex";
import { getCurrentUrl } from "../../../utils/urls";
import { PUBLIC_TEST_URL } from "../../Frontend/constants";
import { TESTS_URL } from "../constants";
import Attributes from "./Attributes";
import ClientDropdown from "./ClientDropdown";
import SheetsSelection from "./SheetsSelection";
import {
  cutStackTrace,
  generateAttr,
  getAttributeValidation,
  getAttributeValues,
} from "./utils";
import { getTranslatedServerMessage } from "../../../utils/translations";
import { useGet } from "../hooks";
import { API_URLS } from "../../../utils/api-constants";

const attributeIds = [1, 2, 3, 4, 5];

function TestForm({ test, onSubmit, error, loading, duplicateMode, editMode }) {
  const clientId = test?.clientId;
  const { data: clientData } = useGet(`${API_URLS.CLIENTS}/${clientId}`, {
    enabled: !!clientId,
  });
  const history = useHistory();
  const { t } = useTranslation();
  const linkMatcher = Yup.string().matches(
    isLinkRegex,
    t("tool.addEdit.incorrectLinkFormat", "Link format is incorrect")
  );

  return (
    <Formik
      initialValues={{
        name: "",
        welcomeHtml: "",
        startHtml: "",
        finalHtml: "",
        startButton: t("tool.addEdit.startBtnInitialText", "Start"),
        nextButton: t("tool.addEdit.nextBtnInitialText", "Next"),
        finishButton: t(
          "tool.addEdit.showResultsBtnInitialText",
          "Show Results"
        ),
        cssLink: "",
        customReportUrl: "",
        maxReplies: 0,
        isActive: true,
        sheetIds: [],
        clientId: "",
        testNumber: "",
        boardStartHtml: "",
        ...getAttributeValues(attributeIds, "attribute"),
        ...getAttributeValues(attributeIds, "specialAttribute"),
        ...(test &&
          Object.keys(test).reduce(
            (acc, key) => ({
              ...acc,
              [key]: (() => {
                if (key.includes("Value")) {
                  return generateAttr(test[key], { compose: true });
                }
                if (key === "testNumber") {
                  return test[key] || "";
                }
                return test[key];
              })(),
            }),
            {}
          )),
      }}
      validationSchema={Yup.object().shape(
        {
          cssLink: linkMatcher,
          customReportUrl: linkMatcher,
          sheetIds: Yup.array()
            .transform(v => v.filter(Boolean))
            .min(1, t("tool.addEdit.minOneSetRequired", "Minimum of 1 set")),
          ...getAttributeValidation(attributeIds, "attribute", t),
          ...getAttributeValidation(attributeIds, "specialAttribute", t),
        },
        [
          ...attributeIds.map(id => [
            `attribute${id}Label`,
            `attribute${id}Value`,
          ]),
          ...attributeIds.map(id => [
            `specialAttribute${id}Label`,
            `specialAttribute${id}Value`,
          ]),
        ]
      )}
      onSubmit={async values => {
        const attrValues = Object.keys(values).reduce(
          (acc, key) =>
            key.includes("Value")
              ? {
                  ...acc,
                  [key]:
                    generateAttr(values[key])?.filter(l => l?.trim()) || "",
                }
              : acc,
          {}
        );
        Object.keys(values).forEach(key => {
          if (key.includes("_") || key === "createdAt" || key === "updatedAt") {
            delete values[key];
          }
        });

        const res = await onSubmit({
          ...values,
          ...attrValues,
          clientId: values.clientId || null,
          sheetIds: values.sheetIds.filter(Boolean),
        });

        if (res) {
          history.push(`${TESTS_URL}/${res._id}`);
        }
      }}
    >
      {({ values, touched, errors, setFieldValue }) => {
        return (
          <Form>
            <Grid container spacing={4}>
              <Grid item sm={3}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12}>
                    <ClientDropdown />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      name="name"
                      as={TextField}
                      required
                      label={t("tool.addEdit.testName", "Name / Description")}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      name="welcomeHtml"
                      as={TextField}
                      label={t("tool.addEdit.welcomeHTML", "Welcome HTML")}
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      name="startHtml"
                      as={TextField}
                      label={t("tool.addEdit.startHtml", "Report start HTML")}
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      name="finalHtml"
                      as={TextField}
                      label={t("tool.addEdit.finalHTML", "Report end HTML")}
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      name="boardStartHtml"
                      as={TextField}
                      label={t(
                        "tool.addEdit.boardStartHtml",
                        "Default Board Start HTML"
                      )}
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {t("tool.addEdit.startLabel", "Start*")}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FastField
                      name="startButton"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {t("tool.addEdit.nextLabel", "Next*")}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FastField
                      name="nextButton"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {t("tool.addEdit.finishLabel", "Finish*")}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FastField
                      name="finishButton"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {t("tool.addEdit.number", "Number")}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FastField
                      name="testNumber"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      type="number"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs sm={3}>
                <Box
                  overflow="auto"
                  maxHeight="790px"
                  minHeight="100px"
                  mt="-10px"
                  pt="10px"
                >
                  <Link
                    href="https://drive.google.com/drive/folders/1Clp2Arxl7b9xI-XNrMEAYb5H4ok4zLOe"
                    target="_blank"
                    style={{
                      position: "absolute",
                      marginTop: "-20px",
                    }}
                    underline="hover"
                  >
                    {t("tool.addEdit.testSets", "Test-Sets")}
                  </Link>
                  <SheetsSelection
                    sheetIds={values.sheetIds}
                    setFieldValue={setFieldValue}
                  />
                </Box>
                {errors.sheetIds && touched.sheetIds && (
                  <Typography color="secondary">{errors.sheetIds}</Typography>
                )}
              </Grid>
              <Grid item sm={6}>
                <Attributes ids={attributeIds} prefix="specialAttribute" />
                <Box height="40px" />
                <Attributes ids={attributeIds} prefix="attribute" />
                <Box
                  component={Grid}
                  container
                  spacing={2}
                  pt="20px"
                  alignItems="center"
                >
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <FormControlLabel
                      label={t("tool.addEdit.active", "Active")}
                      control={
                        <FastField
                          type="checkbox"
                          as={Checkbox}
                          name="isActive"
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>
                      {t("tool.addEdit.maxReplies", "Survey Limit")}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Field
                      name="maxReplies"
                      as={TextField}
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                      inputProps={{
                        min: 0,
                      }}
                      onBlur={() => {
                        if (!values.maxReplies || values.maxReplies < 0) {
                          setFieldValue("maxReplies", 0);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>CSS</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <FastField
                      name="cssLink"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      error={touched.cssLink && Boolean(errors.cssLink)}
                      helperText={touched.cssLink && errors.cssLink}
                      size="small"
                    />
                  </Grid>
                  {loading && <Loader />}
                  {test && !duplicateMode && (
                    <>
                      <Grid item xs={2}>
                        <Typography>ID</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          defaultValue={test._id}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>
                          {t("tool.addEdit.testLink", "Test Link")}
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          defaultValue={getCurrentUrl(
                            `${PUBLIC_TEST_URL}/${test._id}`
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={2}>
                    {t(
                      "tool.addEdit.recommendationsForAction",
                      "Recommendations for action"
                    )}
                  </Grid>
                  <Grid item xs={10}>
                    <Field
                      name="customReportUrl"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      error={
                        touched.customReportUrl &&
                        Boolean(errors.customReportUrl)
                      }
                      helperText={
                        touched.customReportUrl && errors.customReportUrl
                      }
                      disabled={!clientData?.isSpecialReport}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={10}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      {test || editMode || duplicateMode
                        ? t("tool.addEdit.save", "Save")
                        : t("tool.addEdit.create", "Create")}
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={() => {
                        history.push(TESTS_URL);
                      }}
                    >
                      {t("tool.addEdit.close", "Close")}
                    </Button>
                    {error && (
                      <Typography
                        color="secondary"
                        title={cutStackTrace(error?.response?.data?.stack)}
                      >
                        {getTranslatedServerMessage(
                          error?.response?.data?.message
                        ) ??
                          t(
                            "tool.addEdit.unexpectedError",
                            "Unexpected error occurred"
                          )}
                      </Typography>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default TestForm;
