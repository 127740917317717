import React from "react";
import { getFormingsByScale } from "../utils";
import AggregatedScale from "./AggregatedScale";

function TestSetsWithScales({
  testSets,
  entries,
  averageLabel,
  distributionLabel,
}) {
  return (
    <div className="test-sets">
      {testSets.map((testSet, tsIndex) => {
        const formingsByScale = getFormingsByScale(testSet, entries);
        return (
          <div key={`test-set-${tsIndex}`} className="test-set">
            <div className="scales">
              {testSet.scales.map((scale, scaleIndex) => {
                const formings = formingsByScale[scaleIndex];

                if (!formings) {
                  return null;
                }

                return (
                  <AggregatedScale
                    key={`scale-${scaleIndex}-ts${tsIndex}`}
                    averageLabel={averageLabel}
                    distributionLabel={distributionLabel}
                    scale={scale}
                    formings={formings}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TestSetsWithScales;
