import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URLS } from "../../../utils/api-constants";
import { useGet } from "../hooks";

function ClientDropdown() {
  const getClients = useGet(API_URLS.CLIENTS);
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  if (getClients.isError) {
    return <Typography color="secondary">Failed</Typography>;
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={4}>
        <Typography>{t("tool.addEdit.clients", "Clients")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <Select
            value={values.clientId || ""}
            disabled={getClients.isLoading}
            variant="outlined"
            fullWidth
            displayEmpty
            onChange={e => {
              setFieldValue("clientId", e.target.value);
            }}
          >
            <MenuItem value="">
              <em>{t("tool.addEdit.none", "none assigned")}</em>
            </MenuItem>
            {getClients?.data?.map(c => (
              <MenuItem key={c._id} value={c._id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default ClientDropdown;
