import * as Yup from "yup";

export function getAttributeValues(ids, prefix) {
  return ids.reduce(
    (acc, id) => ({
      ...acc,
      [`${prefix}${id}Label`]: "",
      [`${prefix}${id}Value`]: "",
    }),
    {}
  );
}

export function getAttributeValidation(ids, prefix, t) {
  return ids.reduce((acc, id) => {
    const attrLabel = `${prefix}${id}Label`;
    const attrValue = `${prefix}${id}Value`;
    return {
      ...acc,
      [attrLabel]: Yup.string().when(attrValue, {
        is: v => v?.length,
        then: Yup.string().required(
          t(
            "tool.addEdit.attrLabelRequired",
            "Required when the value is added"
          )
        ),
      }),
      [attrValue]: Yup.string().when(attrLabel, {
        is: v => v?.length,
        then: Yup.string()
          .test(
            "sep",
            prefix === "specialAttribute"
              ? t(
                  "tool.addEdit.attrValueMin1Csv",
                  "Must be minimum 1 attribute"
                )
              : t(
                  "tool.addEdit.attrValueMin2Csv",
                  "Must be minimum 2 attributes separated per row"
                ),
            v =>
              generateAttr(v)?.filter(l => l?.trim().length).length >=
              (prefix === "specialAttribute" ? 1 : 2)
          )
          .required(
            t(
              "tool.addEdit.attrValueRequired",
              "Required when the label is added"
            )
          ),
      }),
    };
  }, {});
}

export function generateAttr(attr, opt = {}) {
  if (!attr) return;
  if (opt.compose) {
    return attr.join("\n");
  }
  return attr.split("\n");
}

export function cutStackTrace(message) {
  if (message) {
    return message.split("at new")[0];
  }

  return undefined;
}
