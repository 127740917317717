import { components, OptionProps, GroupBase } from "react-select";
import { MultiValueOption } from "../../types";
import "./styles.scss";

export function SelectableOption({
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: OptionProps<MultiValueOption, boolean, GroupBase<MultiValueOption>>) {
  const style = {
    alignItems: "center",
    backgroundColor: isFocused ? "#eee" : "transparent",
    color: "inherit",
    display: "flex ",
  };

  return (
    <components.Option
      {...rest}
      isFocused={false}
      isSelected={isSelected}
      innerProps={{
        ...innerProps,
        style,
      }}
    >
      <input
        type="checkbox"
        className={"react-select-checkbox"}
        checked={isSelected}
        onChange={() => {}}
      />
      {children}
    </components.Option>
  );
}
