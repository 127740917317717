import { roundAndLocalizeNumber } from "../../../../../utils/formatting";
import { AccordionItemProps } from "../../types";

export const AccordionIndividualItem = ({ question }: AccordionItemProps) => {
  return (
    <li>
      {question.text}
      <div className="custom-grid">
        {Object.entries(question.entries).map(([k, v], eIndex) => {
          return (
            <div
              key={k}
              className={`column-${eIndex + 1} ${v === 0 ? "opacity-1" : ""}`}
              style={{
                width: "100%",
              }}
            >
              {v > 0 && (
                <span className="percentage">{roundAndLocalizeNumber(v)}</span>
              )}
            </div>
          );
        })}
      </div>
    </li>
  );
};
