import { Accordion } from "react-bootstrap";
import { TranslationFunc } from "../../../../../@types";
import { getReportItems } from "../../utils";
import { ReportProps } from "../../types";
import React from "react";
import { useTranslation } from "react-i18next";

type ReportItem = {
  header: string;
  subHeader: string;
  text: string;
};

type SectionName = "section1" | "section2" | "section3";

type ReportItemsTuple = [SectionName, ReportItem[]];

const SectionHeaderMap = {
  section1: (t: TranslationFunc) =>
    t("frontend.customReport.section1", "Strengths you can build on"),
  section2: (t: TranslationFunc) =>
    t("frontend.customReport.section2", "Challenges to work on"),
  section3: (t: TranslationFunc) =>
    t("frontend.customReport.section3", "What to keep in mind"),
};

export const AccordionPersonalItem = ({
  data,
  isIndividual = false,
}: ReportProps) => {
  const { t } = useTranslation();
  const reportItems = getReportItems(data, isIndividual);
  const sectionItems = reportItems
    ? (Object.entries(reportItems) as ReportItemsTuple[])
    : null;

  if (!reportItems) {
    return null;
  }

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        <img className="icon-action" style={{ marginRight: "20px" }} alt="" />
        {isIndividual ? "Persönliche Handlungsimpulse" : "Handlungsimpulse"}
      </Accordion.Header>
      <Accordion.Body>
        <ul className="list-1">
          {sectionItems!.map(
            ([section, items]: ReportItemsTuple, index: number) => (
              <React.Fragment key={index}>
                {items.length > 0 && (
                  <li>
                    <div className="row">
                      <div className="col-12 col-sm-3">
                        <img
                          className={`traffic-light traffic-light-img-${index}`}
                          style={{ marginTop: "-10px" }}
                          alt=""
                        />
                      </div>
                      <div className="col-12 col-sm-8">
                        <h2>{SectionHeaderMap[section](t)}</h2>
                      </div>
                    </div>
                  </li>
                )}
                {items.map((item: ReportItem, itemIndex: number) => (
                  <li key={itemIndex}>
                    <div className="row">
                      <div className="col-12 col-sm-3">
                        <b>
                          {item.header}
                          <br />
                          {item.subHeader}
                        </b>
                      </div>
                      <div className="col-12 col-sm-8">{item.text}</div>
                    </div>
                  </li>
                ))}
              </React.Fragment>
            )
          )}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
};
