import React from "react";
import { useLocation } from "react-router-dom";
import { API_URLS } from "../../../utils/api-constants";
import { usePost } from "../hooks";
import PageWrapper from "../Shared/PageWrapper";
import GroupForm from "./Form";

function AddGroup() {
  const location = useLocation();
  const testId = new URLSearchParams(location.search).get("testId");
  const createGroup = usePost(API_URLS.GROUPS);

  return (
    <PageWrapper>
      <GroupForm
        testId={testId}
        group={createGroup.data}
        loading={createGroup.isLoading}
        error={createGroup.error}
        onSubmit={createGroup.mutateAsync}
      />
    </PageWrapper>
  );
}

export default AddGroup;
