import {
  matchPath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { PUBLIC_COCKPIT_URL } from "../../constants";
import { HydratedField } from "../types";
import { TagProps } from "./components/Tag/types";
import { calculateItemPosition, parsePosition } from "./positioning-utils";
import { Position } from "./types";

export const createTag = (
  field: HydratedField,
  { x, y }: Position,
  currentField?: HydratedField
) => {
  return {
    field,
    x: `${x}%`,
    y: `${y}%`,
    lines: Math.ceil(field.name.length / 22),
    bgOpacity: field.opacity,
    isCurrent: currentField && currentField.id === field.id,
  };
};

export const createTags = (
  fields: HydratedField[],
  currentField?: HydratedField
) => {
  return fields.map<TagProps>(field => {
    const position: Position =
      field.quadrant === 0
        ? parsePosition(field.position)
        : calculateItemPosition(
            parseInt(field.position, 10) - 1,
            field.quadrant
          );
    return createTag(field, position, currentField);
  });
};

export function someUrlsMatches(
  urls: string[],
  pathName: string,
  isNot: boolean
) {
  if (isNot) {
    return urls.every(pattern => {
      return !matchPath(pattern, pathName);
    });
  } else {
    return urls.some(pattern => {
      return !!matchPath(pattern, pathName);
    });
  }
}

export function useUrlMatching(urls: string[], isNot: boolean = false) {
  const location = useLocation();
  return someUrlsMatches(urls, location.pathname, isNot);
}

export function useNavigateField() {
  const { clientId } = useParams<any>();
  const history = useHistory();

  return (fieldId: string) => {
    history.push(`${PUBLIC_COCKPIT_URL}/${clientId}/${fieldId}`);
  };
}

export function ceilValueByScale(value: number, scale: number) {
  return Math.ceil(value / scale) * scale;
}

export function getFieldOpacity(weightValue: number) {
  return ceilValueByScale(weightValue, 0.2);
}

export const isTopQuadrant = (quadrant: number) =>
  quadrant === 1 || quadrant === 2;

export const isLeftQuadrant = (quadrant: number, position: string | null) => {
  if (quadrant === 0) {
    return parsePosition(position!).x < 40;
  }

  return quadrant === 1 || quadrant === 3;
};
