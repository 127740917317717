import React, { Fragment, useState } from "react";
import { GenericObject } from "../../../../@types";
import TestSetsWithScales from "../../AggregatedDashboard/components/TestSetsWithScales";
import { VISUALIZATIONS_TYPES } from "../../constants";
import Visualization from "../../Test/sub-pages/TestResults/visualization";
import { useNavigateField } from "../Radar/utils";
import { StepWrapper } from "../styled";
import { DtoTestSet, HydratedField } from "../types";
import {
  AverageVisualisationContainer,
  StyledConnectedFields,
  StyledField,
  StyledHeading,
  StyledSmallText,
  StyledWrapper,
} from "./styled";
import Tag from "./Tag/";
import Statistic from "./Statistic/";

type Props = {
  currentField?: HydratedField;
  hydratedFields: HydratedField[];
  testSets: Array<DtoTestSet>;
  entries: Array<GenericObject>;
  labels: GenericObject<string>;
};

const averageScale = {
  formingAmount: 7,
  formingVisualization: VISUALIZATIONS_TYPES.GAUGE,
};

function FieldsDetails(props: Props) {
  const { currentField, testSets, entries, labels } = props;
  const navigateField = useNavigateField();
  const [shouldExpandConnectedFields, setShouldExpandConnectedFields] =
    useState(false);
  const [shouldExpandStatistic, setShouldExpandStatistic] = useState(false);

  if (!currentField) {
    return null;
  }

  return (
    <StepWrapper>
      <StyledField>
        <Tag
          type={{ baseColor: currentField.color }}
          name={currentField.name}
          quadrant={currentField.quadrant}
        />
      </StyledField>
      <StyledWrapper>{currentField.description}</StyledWrapper>
      {!!currentField.visualisationValue && (
        <AverageVisualisationContainer>
          <Visualization
            id={`average-scale-${currentField.id}`}
            scale={averageScale}
            percent={currentField.visualisationValue}
          />
        </AverageVisualisationContainer>
      )}
      <StyledWrapper>
        <StyledHeading
          onClick={() => {
            setShouldExpandConnectedFields(!shouldExpandConnectedFields);
          }}
        >
          {shouldExpandConnectedFields ? <div>▲</div> : <div>▼</div>}
          <div>{labels.expandConnectionsLabel}</div>
        </StyledHeading>
        {shouldExpandConnectedFields && (
          <StyledConnectedFields>
            {currentField.connectedFields.map(f => (
              <Fragment key={f.id}>
                <Tag
                  type={{ baseColor: f.color }}
                  name={f.name}
                  quadrant={f.quadrant}
                  onClick={() => {
                    navigateField(f.id);
                  }}
                />
                <StyledSmallText
                  dangerouslySetInnerHTML={{
                    __html: f.connectionLabel,
                  }}
                />
              </Fragment>
            ))}
          </StyledConnectedFields>
        )}
      </StyledWrapper>
      <TestSetsWithScales
        entries={entries}
        testSets={testSets}
        averageLabel={labels.averageLabel}
        distributionLabel={labels.distributionLabel}
      />
      <StyledHeading
        style={{ marginTop: "30px" }}
        onClick={() => {
          setShouldExpandStatistic(!shouldExpandStatistic);
        }}
      >
        {shouldExpandStatistic ? <div>▲</div> : <div>▼</div>}
        <div>{labels.expandItemsLabel}</div>
      </StyledHeading>
      {shouldExpandStatistic && (
        <Statistic testSets={testSets} entries={entries} />
      )}
    </StepWrapper>
  );
}

export default FieldsDetails;
