import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useInterval from "use-interval";
import Loader from "../../../components/Loader";
import { setFavIcon, tryEmbedCssLink } from "../../../utils/embed-utils";
import { axiosGet } from "../../../utils/queries";
import TestSetsWithScales from "./components/TestSetsWithScales";
import { STOP_LIST_URL, UPDATE_INTERVAL } from "./constants";
import {
  buildWordCloudList,
  getGroupUrl,
  getReportUrl,
  getStartHtml,
  getStopListWordsFromSheetResponse,
  getTestFlowsCountByEntries,
} from "./utils";
import Statistic from "../Cockpit/PickKeysForField/Statistic";
import { StyledHeading } from "../Cockpit/PickKeysForField/styled";
import { useTranslation } from "react-i18next";
import { AttributeFilters } from "../components/AttributeFilters";
import { filterEntries } from "../utils";
import "./styles.scss";

function AggregatedDashboard({ isGroup }) {
  const { t } = useTranslation();
  const { entityId } = useParams();
  const entityApiUrl = isGroup ? getGroupUrl(entityId) : getReportUrl(entityId);

  const [isLoading, setIsLoading] = useState(true);
  const [staticData, setStaticData] = useState(null);
  const [dynamicData, setDynamicData] = useState({});
  const [stopWordsSet, setStopWordsSet] = useState(null);
  const [shouldExpandStatistic, setShouldExpandStatistic] = useState(false);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const repliesCount = dynamicData?.entries?.length || 0;
  const reportMinParticipants = staticData?.client?.reportMinParticipants || 0;

  useEffect(() => {
    Promise.all([axiosGet(STOP_LIST_URL), axiosGet(entityApiUrl)]).then(
      ([wordCloudStopListData, initialData]) => {
        const { entries, ...staticDataProps } = initialData;

        const initialStopWordsSet = new Set(
          getStopListWordsFromSheetResponse(wordCloudStopListData)
        );
        const wordCloudList = buildWordCloudList(entries, initialStopWordsSet);

        setStopWordsSet(initialStopWordsSet);
        setStaticData(staticDataProps);
        setDynamicData({ entries, wordCloudList });
        setFilteredEntries(entries);
        setIsLoading(false);

        if (staticDataProps.cssLink) {
          tryEmbedCssLink(staticDataProps.cssLink);
        } else if (staticDataProps.client) {
          tryEmbedCssLink(staticDataProps.client.cssLink);
        }

        if (staticDataProps.client.favIconUrl) {
          setFavIcon(staticDataProps.client.favIconUrl);
        }

        if (staticDataProps.name) {
          document.title = staticDataProps.name;
        }
      }
    );
  }, [entityApiUrl]);

  useInterval(() => {
    axiosGet(`${entityApiUrl}?partialRefresh=true`).then(({ entries }) => {
      if (entries.length > 0 && entries.length !== dynamicData.entries.length) {
        const wordCloudList = buildWordCloudList(entries, stopWordsSet);
        setDynamicData({ entries, wordCloudList });
        setFilteredEntries(filterEntries(entries, selectedAttributes));
      }
    });
  }, UPDATE_INTERVAL);

  if (isLoading) {
    return <Loader />;
  }

  const testsFlowCount = getTestFlowsCountByEntries(
    filteredEntries,
    staticData.onlyCompleted
  );
  const shouldHideReport = !(testsFlowCount >= reportMinParticipants); // It's intended

  return (
    <div className="tg_page page aggregated-dashboard-page aggregated-report-page">
      <div
        className="entity-description report-description"
        dangerouslySetInnerHTML={{ __html: getStartHtml(staticData) }}
      />
      <div className="tg_column">
        {!!repliesCount && (
          <>
            {staticData?.client.reportAttrFilters && (
              <AttributeFilters
                test={staticData.test}
                onChange={attrs => {
                  setSelectedAttributes(attrs);
                  setFilteredEntries(filterEntries(dynamicData.entries, attrs));
                }}
              />
            )}
            {!!testsFlowCount && !shouldHideReport && (
              <>
                <TestSetsWithScales
                  entries={filteredEntries}
                  testSets={staticData.testSets}
                  averageLabel={staticData.averageLabel}
                  distributionLabel={staticData.distributionLabel}
                />
                <StyledHeading
                  className="statistic-toggle"
                  onClick={() => {
                    setShouldExpandStatistic(!shouldExpandStatistic);
                  }}
                >
                  {shouldExpandStatistic ? <div>▲</div> : <div>▼</div>}
                  <div>
                    {staticData.expandItemsLabel ||
                      t("tool.groupForm.expandItemsLabel")}
                  </div>
                </StyledHeading>
                {shouldExpandStatistic && (
                  <div className="statistic-container">
                    <Statistic
                      entries={filteredEntries}
                      testSets={staticData.testSets}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AggregatedDashboard;
