import axios from "axios";

axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = 10000;

export const client = axios;

function jsonConfig(config) {
  config.headers = {
    ...config.headers,
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  return config;
}

function request(config) {
  jsonConfig(config);

  return client.request(config);
}

export function login({ email, password }) {
  return request({
    url: "/auth/login",
    method: "POST",
    data: { email, password },
  });
}

export default request;
