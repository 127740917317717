import { Backdrop, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import "./styles.scss";

const PREFIX = "Loader";

const classes = {
  backdrop: `${PREFIX}-backdrop`,
};

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  [`&.${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "transparent",
  },
}));

function Loader(props) {
  return (
    <StyledBackdrop className={classes.backdrop} open {...props}>
      <CircularProgress color="primary" />
    </StyledBackdrop>
  );
}

export default Loader;
