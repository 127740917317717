export const PUBLIC_TEST_URL = "/test";
export const PUBLIC_REPORT_URL = "/report";
export const PUBLIC_GROUP_URL = "/group";
export const CUSTOM_PUBLIC_REPORT_URL = "/creport";
export const CUSTOM_PUBLIC_GROUP_URL = "/cgroup";
export const PUBLIC_COCKPIT_URL = "/cockpit";
export const ATTRIBUTES_SUB_URL = "attributes";
export const SPECIAL_ATTRIBUTES_SUB_URL = "special-attributes";
export const RESULTS_SUB_URL = "results";
export const CUSTOM_RESULTS_SUB_URL = "cresults";
export const PUBLIC_SITE_URL = "https://www.management-analytics.de";

export const TEST_TYPES = {
  LIKERT: "L",
  TEXT: "T",
  CHOICE: "C",
  NUMERICAL: "N",
  MULTI_CHOICE: "M",
  SUB_HEADING: "S",
};

export const VISUALIZATIONS_TYPES = {
  TRAFFIC_LIGHT: "A",
  GAUGE: "G",
  CATEGORY: "C",
  TEXT: "T",
  NO_VISUALIZATION: "N",
};

export const MAX_ATTRIBUTES_COUNT = 5;
