import { sortBy } from "lodash-es";
import hexRgb from "hex-rgb";
import { GenericObject } from "../../../../@types";
import { FIELDS_STEP } from "./useHydratedFields";
import { DtoEntry, DtoField, DtoTestSet } from "../types";
import { getFormingsByScaleUnfiltered } from "../../AggregatedDashboard/utils";
import { VISUALIZATIONS_TYPES } from "../../constants";
import { round, sum } from "lodash-es";
import { COLORS } from "../../Test/sub-pages/TestResults/visualization";

const FULL_COLORS_SCALE = COLORS["7"];
const NO_REPLIES_COLOR = "#969696";

function convertHexToRgb(hex: string) {
  const rgbArray = hexRgb(hex, { format: "array" });
  rgbArray.pop();
  return rgbArray.join(", ");
}

function getColor(val: number) {
  let color = "";

  if (val === 0) {
    color = convertHexToRgb(NO_REPLIES_COLOR);
  } else if (val < 1 / 7) {
    color = convertHexToRgb(FULL_COLORS_SCALE[0]);
  } else if (val < 2 / 7) {
    color = convertHexToRgb(FULL_COLORS_SCALE[1]);
  } else if (val < 3 / 7) {
    color = convertHexToRgb(FULL_COLORS_SCALE[2]);
  } else if (val < 4 / 7) {
    color = convertHexToRgb(FULL_COLORS_SCALE[3]);
  } else if (val < 5 / 7) {
    color = convertHexToRgb(FULL_COLORS_SCALE[4]);
  } else if (val < 6 / 7) {
    color = convertHexToRgb(FULL_COLORS_SCALE[5]);
  } else if (val <= 1) {
    color = convertHexToRgb(FULL_COLORS_SCALE[6]);
  } else {
    console.error("Wrong calculations", val);
  }

  return color;
}

export function getTesSetsVisualisationValues(
  testSets: DtoTestSet[],
  entriesBySheetMap: GenericObject<Array<GenericObject>>
) {
  return testSets.reduce<GenericObject<number>>((acc, testSet) => {
    const formingsByScale = getFormingsByScaleUnfiltered(
      testSet,
      entriesBySheetMap[testSet.spreadsheetId] || []
    );
    const formingsAllScales = testSet.scales
      .map((scale, scaleIndex) => {
        if (
          scale.formingVisualization === VISUALIZATIONS_TYPES.GAUGE ||
          scale.formingVisualization === VISUALIZATIONS_TYPES.TRAFFIC_LIGHT
        ) {
          const formings = formingsByScale[scaleIndex];
          if (formings) {
            const ns = scale.formingAmount - 1;
            const average = round(sum(formings) / formings.length, 2);
            return (average - 1) / ns;
          }
        }

        return undefined;
      })
      .filter(average => average !== undefined);

    acc[testSet.spreadsheetId] = formingsAllScales.length
      ? round(sum(formingsAllScales) / formingsAllScales.length, 2)
      : 0;

    return acc;
  }, {});
}

export function getColorsMapByTestSet(
  visualisationValues: GenericObject<number>
) {
  return Object.keys(visualisationValues).reduce<GenericObject<string>>(
    (acc, key) => {
      acc[key] = getColor(visualisationValues[key]);
      return acc;
    },
    {}
  );
}

export function getTotalEntriesByField(dtoEntries: DtoEntry[]) {
  return dtoEntries.reduce<{ total: number } & GenericObject<number>>(
    (acc, entry) => {
      const count = entry.entries.length;
      acc[entry.fieldId] = entry.entries.length;
      acc.total += count;
      return acc;
    },
    { total: 0 }
  );
}

export function getFieldsOpacity(fields: DtoField[], dtoEntries: DtoEntry[]) {
  const totalEntriesByField = getTotalEntriesByField(dtoEntries);

  const distribution = sortBy(
    fields.map(f => ({
      fieldId: f.id,
      percentage: totalEntriesByField[f.id] / totalEntriesByField.total,
    })),
    "percentage"
  );

  const result: GenericObject<number> = {};

  for (let i = FIELDS_STEP; i > 0; i--) {
    const subPart = distribution.splice(0, Math.ceil(distribution.length / i));
    subPart.forEach(sp => {
      result[sp.fieldId] = 1 / i;
    });
  }

  return result;
}

// show just text if no replies were found earlier
export function correctColorAndOpacity(color: string, opacity: number) {
  if (!color) {
    return {
      color: convertHexToRgb(NO_REPLIES_COLOR),
      opacity,
    };
  }

  return {
    color,
    opacity,
  };
}
