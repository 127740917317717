import { axiosPost } from "../../../../../utils/queries";

let cancellablePromise;

export const oneTimeAxiosPost = (url, options) => {
  if (cancellablePromise) {
    cancellablePromise.cancel();
  }

  cancellablePromise = axiosPost(url, options);

  return cancellablePromise;
};
