export const ReportFarewell = () => {
  return (
    <>
      <p className="thankyou">Vielen Dank für Ihre Teilnahme!</p>
      <p className="farewell">
        Falls Sie Fragen dazu haben, wie die Ergebnisse dieses QuickChecks
        zusammengefasst und verwendet werden, wenden Sie sich an Ihre
        Führungskraft oder Ihre Ansprechpartnerin bzw. Ansprechpartner beim
        Bildungswerk der Baden-Württembergischen Wirtschaft.
      </p>
    </>
  );
};
