import { groupBy } from "lodash-es";
import { API_URLS } from "../../../utils/api-constants";

export const extractWords = (str: string) => {
  return str.match(/[a-zA-Z\u0080-\uFFFF]+('[a-zA-Z\u0080-\uFFFF]+)?/g) || [];
};

export const getReportUrl = (reportId: string) =>
  `${API_URLS.PUBLIC_TEST}/reports/${reportId}`;

export const getGroupUrl = (groupId: string) =>
  `${API_URLS.PUBLIC_TEST}/groups/${groupId}`;

export const buildWordCloudList = (entries: any[], stopList: Set<any>) => {
  const words = getWordsFromEntries(entries).filter(
    (word: string) => !stopList.has(word)
  );

  return Object.entries(
    words.reduce((acc: any, word: string) => {
      if (!acc[word]) {
        acc[word] = 0;
      }

      acc[word] += 1;

      return acc;
    }, {})
  );
};

const getWordsFromEntries = (entries: any[]) => {
  return entries.reduce((acc, entry) => {
    for (let i = 1; i <= 10; i++) {
      const textVal = entry[`T${i}`] || "";
      const words = extractWords(textVal);
      if (words.length) {
        acc = [...acc, ...words];
      }
    }

    return acc;
  }, []);
};

export const getStopListWordsFromSheetResponse = (response: any) => {
  return response.reduce((acc: any, item: any) => {
    item.en && acc.push(item.en);
    item.de && acc.push(item.de);
    return acc;
  }, []);
};

export const getWordCloudConfig = (list: any, canvas: any) => {
  return {
    list: list,
    gridSize: Math.round((50 * canvas.width) / 1024),
    weightFactor: (size: number) => {
      return size * (200 / list.length);
    },
    rotateRatio: 0.2,
    rotationSteps: 2,
    backgroundColor: "#f9f9fb",
  };
};

const getDataByScaleUnfiltered = (
  key: string,
  testSet: any,
  entries: any[]
) => {
  return entries.reduce((acc, entry) => {
    for (let i = 0; i < testSet.scales.length; i++) {
      if (!acc[i]) {
        acc[i] = [];
      }
      acc[i].push(entry[`S${i + 1}${key}`]);
    }

    return acc;
  }, {});
};

export const getFormingsByScaleUnfiltered = (testSet: any, entries: any[]) => {
  return getDataByScaleUnfiltered("F", testSet, entries);
};

export const getValuesByScaleUnfiltered = (testSet: any, entries: any[]) => {
  return getDataByScaleUnfiltered("V", testSet, entries);
};

export const filterEntriesByTestSet = (testSet: any, entries: any[]) => {
  return entries.filter(entry => entry.testSetId === testSet._id);
};

export const getFormingsByScale = (testSet: any, entries: any[]) => {
  const testSetEntries = filterEntriesByTestSet(testSet, entries);
  return getFormingsByScaleUnfiltered(testSet, testSetEntries);
};

export const getValuesByScale = (testSet: any, entries: any[]) => {
  const testSetEntries = filterEntriesByTestSet(testSet, entries);
  return getValuesByScaleUnfiltered(testSet, testSetEntries);
};

export const getStartHtml = (staticData: any) => {
  return staticData?.test?.boardStartHtml || staticData.welcomeHtml || "";
};

export const getTestFlowsCountByEntries = (
  allEntries: any[],
  onlyCompleted: boolean = false
) => {
  let entries = allEntries;

  if (onlyCompleted) {
    entries = entries.filter(entry => entry.completed);
  }

  const groupedEntries = groupBy(entries, "sessionId");
  return Object.keys(groupedEntries).length;
};
