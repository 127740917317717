import { HydratedData } from "./hooks/useHydratedData";

function getReportItemsHandler(isIndividual: boolean) {
  return isIndividual
    ? // @ts-ignore
      window.biweGenerateTextsIndividual
    : // @ts-ignore
      window.biweGenerateTextsGroup;
}

export function getReportItems(data: HydratedData, isIndividual: boolean) {
  try {
    return (
      getReportItemsHandler(isIndividual)?.(data.testSetsData.testSets, {
        minForming: data.testSetsData.minForming,
        maxForming: data.testSetsData.maxForming,
        minAvgForming: data.testSetsData.minAvgForming,
        maxAvgForming: data.testSetsData.maxAvgForming,
        minAvg: data.testSetsData.minAvg,
        maxAvg: data.testSetsData.maxAvg,
      }) || null
    );
  } catch (e: any) {
    console.error(e);
    return null;
  }
}
