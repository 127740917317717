import { orderBy } from "lodash-es";
import { GenericObject } from "../../../@types";
import { DtoSetting, FieldsConnectionMap, ProcessedMatrix } from "./types";

export function getIndex(i: number) {
  return (i + 1).toString();
}

export function createEmptyArray(count: number) {
  return Array(count).fill(undefined);
}

export function processMatrix<TValueType>(
  matrix: Array<GenericObject<TValueType>>
): ProcessedMatrix<TValueType> {
  return matrix.reduce<GenericObject>((acc, item, i) => {
    acc[getIndex(i)] = item;
    return acc;
  }, {});
}

export function getStaticConnections(
  matrix: GenericObject,
  connectionLabels: GenericObject
) {
  const keys = Object.keys(matrix);
  return keys.reduce<FieldsConnectionMap>((acc, key) => {
    const values = orderBy(
      Object.keys(matrix[key])
        .filter(innerKey => innerKey !== key)
        .map(innerKey => ({
          field: innerKey,
          value: matrix[key][innerKey],
          label: connectionLabels[key][innerKey],
        }))
        .filter(({ value }) => value > 0),
      ["value"],
      ["desc"]
    );
    acc[key] = values.map(item => ({
      ...item,
      size: item.value,
    }));
    return acc;
  }, {});
}

export function keyByName(itemsCollection: DtoSetting[]) {
  return itemsCollection.reduce<GenericObject<string>>((acc, item) => {
    acc[item.name] = item.value;
    return acc;
  }, {});
}
