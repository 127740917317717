import React from "react";
import {
  Question,
  TestDetails,
  TrafficLightsDistribution,
} from "./hooks/types";
import { HydratedData } from "./hooks/useHydratedData";

export type EntityResponse = {
  _id: string;
  name: string;
  client: {
    isSpecialReport: boolean;
    reportCssUrl: string;
    favIconUrl: string;
    disableIndividualReport: boolean;
    reportAttrFilters: boolean;
    reportMinParticipants?: number;
  };
  test: {
    completedTestFlowsCount: number;
    _id: string;
    name: string;
    customReportUrl?: string;
  };
  testSets: {
    _id: string;
    name: string;
    testId: string;
    info: {
      headline: string;
    };
    items: {
      [key: string]: any;
      dbColumn: string;
      values: number;
      type: string;
      text: string;
    }[];
    scales: {
      [key: string]: any;
      scaleName: string;
      _id: string;
    }[];
  }[];
  entries: {
    [key: string]: any;
  }[];
  onlyCompleted: boolean;
};

export enum TrafficLight {
  Green = "GREEN",
  Yellow = "YELLOW",
  Red = "RED",
}

export type TrafficLightValues<TValueType = string> = {
  [key in TrafficLight]: TValueType;
};

export type ReportProps = {
  data: HydratedData;
  isIndividual?: boolean;
  FiltersComponent?: React.ReactNode;
};

export type TrafficLightsProps = {
  trafficLights: TrafficLight[];
};

export type DistributionProps = {
  trafficLightsDistribution: TrafficLightsDistribution;
};

export type DetailsChartProps = {
  testDetails: TestDetails;
  detailsOpen: boolean;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  isIndividual: boolean;
};

export type AccordionItemProps = {
  question: Question;
};
