import queryString from "query-string";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { Context } from "../../store/store";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
  axiosPut,
} from "../../utils/queries";
import { FILTER_BY_CLIENT } from "./constants";

export function useGet(key, rq, axios) {
  return useQuery(
    key,
    ({ queryKey: [url, params] }) => axiosGet(url, { params, ...axios }),
    { refetchOnWindowFocus: false, ...rq }
  );
}

export function usePost(key, rq, axios) {
  const [url] = [...[key].flat()];
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      axiosPost(url, {
        data,
        ...axios,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key);
      },
      ...rq,
    }
  );
}

export function usePut(key, rq, axios) {
  const [url] = [...[key].flat()];
  const queryClient = useQueryClient();

  return useMutation(
    data => {
      const { id, ...req } = data;
      return axiosPut(id ? `${url}/${id}` : url, {
        data: req,
        ...axios,
      });
    },
    {
      onSuccess: data => {
        queryClient.setQueriesData(key, data);
      },
      ...rq,
    }
  );
}

export function usePatch(key, rq, axios) {
  const [url] = [...[key].flat()];
  const queryClient = useQueryClient();

  return useMutation(
    data => {
      const { id, ...req } = data;
      return axiosPatch(id ? `${url}/${id}` : url, {
        data: req,
        ...axios,
      });
    },
    {
      onSuccess: data => {
        queryClient.setQueriesData(key, data);
      },
      ...rq,
    }
  );
}

export function useDelete(key, rq, axios) {
  const [url] = [...[key].flat()];
  const queryClient = useQueryClient();

  return useMutation(id => axiosDelete(id ? `${url}/${id}` : url, axios), {
    onSuccess: data => {
      queryClient.setQueriesData(key, (prev = []) => {
        if (prev.docs) {
          return {
            ...prev,
            docs: prev.docs.filter(p => p._id !== data._id),
          };
        }
        return prev.filter(p => p._id !== data._id);
      });
    },
    ...rq,
  });
}

export function useClientId() {
  const [state] = useContext(Context);
  return state.selectedClientId;
}

export function useGetList(key) {
  const clientId = useClientId();
  const location = useLocation();
  const [keyName, params = {}] = [...[key].flat()];

  return useGet([
    keyName,
    {
      ...params,
      clientId: FILTER_BY_CLIENT[location.pathname] ? clientId : undefined,
    },
  ]);
}

export function useQueryParams() {
  const location = useLocation();
  return queryString.parse(location.search);
}
