import React from "react";

function Check(props) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 29 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-372 -3139)" fill="#fff" fillRule="nonzero">
          <g transform="translate(164 2637)">
            <g transform="translate(177 490)">
              <g transform="translate(22)">
                <path d="m34.778 12.778c0.53697-0.53697 1.4076-0.53697 1.9445 0 0.47731 0.47731 0.53034 1.2182 0.1591 1.7541l-0.1591 0.19043-16.5 16.5c-0.47731 0.47731-1.2182 0.53034-1.7541 0.1591l-0.19043-0.1591-8.25-8.25c-0.53697-0.53697-0.53697-1.4076 0-1.9445 0.47731-0.47731 1.2182-0.53034 1.7541-0.1591l0.19043 0.1591 7.2777 7.278 15.528-15.528z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Check;
