import { TagWrapper } from "./styled";
import { getFieldOpacity } from "../../Radar/utils";

type TagProps = {
  name: string;
  type: any;
  quadrant: number;
  weight?: number;
  pointer?: boolean;
  onClick?: () => void;
};

const LINES_TO_FONT = [15, 14, 11, 11];

function Tag({ name, type, weight = 1, pointer, onClick }: TagProps) {
  const opacity = getFieldOpacity(weight);

  const backgroundColor = `rgba(${type.baseColor}, ${opacity})`;
  const borderColor = `rgb(${type.baseColor})`;

  const lines = Math.ceil(name.length / 18);
  const fontSize = LINES_TO_FONT[lines];

  return (
    <TagWrapper
      onClick={onClick}
      backgroundColor={backgroundColor}
      pointer={pointer}
      borderColor={borderColor}
    >
      <span
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${fontSize}px`,
          textAlign: `center`,
        }}
        dangerouslySetInnerHTML={{ __html: name }}
      />
    </TagWrapper>
  );
}

export default Tag;
