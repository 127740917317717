import {
  Button,
  Grid,
  TextField,
  Typography,
  FormLabel,
  Box,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  FastField,
  Form,
  Formik,
  useFormikContext,
  Field,
  useField,
} from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import { getCurrentUrl } from "../../../utils/urls";
import { CLIENTS_URL } from "../constants";

const COCKPIT_URL = process.env.REACT_APP_START_COCKPIT_URL;
const REPORT_TYPE = {
  default: "default",
  special: "special",
};

function InnerForm({ client, error, loading, editMode }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    values: { startCockpitSheetId = "", startCockpitResKey = "" },
  } = useFormikContext();

  const [{ value: isSpecialReport }, , { setValue: setIsSpecialReport }] =
    useField({ name: "isSpecialReport", value: !!client?.isSpecialReport });

  const handleReportTypeChange = (event, value) => {
    setIsSpecialReport(value === REPORT_TYPE.special);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={7}>
        <FastField
          name="name"
          as={TextField}
          required
          label={t("tool.clientForm.name", "Name")}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={7}>
        <FastField
          name="cssLink"
          as={TextField}
          label={t("tool.clientForm.cssLink", "CSS Link")}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {client && (
              <TextField
                label="Client ID"
                variant="outlined"
                defaultValue={client._id}
                fullWidth
                size="small"
                disabled={true}
                sx={{ margin: "24px 0 24px" }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <FormLabel>{t("tool.clientForm.favIcon", "Fav Icon")}</FormLabel>
            <Field
              variant="outlined"
              fullWidth
              name="favIconUrl"
              as={TextField}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormLabel>
              {t("tool.clientForm.resultCockpit", "Result Cockpit")}
            </FormLabel>
            <FastField
              name="dashboardConfigSheetId"
              as={TextField}
              variant="outlined"
              fullWidth
              size="small"
              placeholder={t(
                "tool.clientForm.sheetId",
                "sheetId(set to override default)"
              )}
              sx={{ margin: "0 0 24px" }}
            />
            <FastField
              name="resultCockpitHeaderHtml"
              as={TextField}
              label={t(
                "tool.clientForm.resultCockpitHeaderHtml",
                "Result Header HTML"
              )}
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              sx={{ margin: "64px 0 24px" }}
            />
            {client && (
              <TextField
                variant="outlined"
                fullWidth
                defaultValue={getCurrentUrl(`/cockpit/${client._id}`)}
                size="small"
                placeholder="Cockipit url"
                disabled={true}
                sx={{ margin: "0 0 24px" }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <FormLabel>
              {t("tool.clientForm.startRadar", "Start Radar")}
            </FormLabel>
            <FastField
              name="startCockpitResKey"
              as={TextField}
              variant="outlined"
              fullWidth
              size="small"
              placeholder={t(
                "tool.clientForm.resKey",
                "Res-Key (optional to override default)"
              )}
              sx={{ margin: "0 0 24px" }}
            />
            <FastField
              name="startCockpitSheetId"
              as={TextField}
              variant="outlined"
              fullWidth
              size="small"
              placeholder={t(
                "tool.clientForm.sheetId",
                "Sheet-ID(set to override default)"
              )}
              sx={{ margin: "0 0 24px" }}
            />
            <FastField
              name="startCockpitHeaderHtml"
              as={TextField}
              label={t(
                "tool.clientForm.startCockpitHeaderHtml",
                "Start Header HTML"
              )}
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              sx={{ margin: "0 0 24px" }}
            />
            {client && (
              <TextField
                variant="outlined"
                fullWidth
                value={`${COCKPIT_URL}/?clientId=${client._id}&sheetId=${startCockpitSheetId}&localeId=${startCockpitResKey}`}
                size="small"
                placeholder="Start Cockpit URL"
                disabled={true}
                sx={{ margin: "0 0 24px" }}
              />
            )}
          </Grid>
        </Grid>
        <FormLabel>{t("tool.clientForm.report", "Report")}</FormLabel>
        <Box
          component={Grid}
          item
          xs={8}
          p="10px 15px"
          m={"0 0 15px"}
          border="1px solid #bfbfc1"
          borderRadius={1}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box component={Grid} container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <RadioGroup
                    name="reportRadioGroup"
                    row={true}
                    value={
                      isSpecialReport
                        ? REPORT_TYPE.special
                        : REPORT_TYPE.default
                    }
                    onChange={handleReportTypeChange}
                  >
                    <FormControlLabel
                      value={REPORT_TYPE.default}
                      control={<Radio />}
                      label={t("tool.clientForm.default", "Default")}
                    />
                    <FormControlLabel
                      value={REPORT_TYPE.special}
                      control={<Radio />}
                      label={t("tool.clientForm.special", "Special")}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    {t(
                      "tool.clientForm.minimumParticipants",
                      "Minimum Participants"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <FastField
                    name="reportMinParticipants"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    label={t("tool.clientForm.featureFilter", "Feature Filter")}
                    control={
                      <FastField
                        type="checkbox"
                        as={Checkbox}
                        name="reportAttrFilters"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    label={t(
                      "tool.clientForm.noIndividualReport",
                      "No Individual Report"
                    )}
                    control={
                      <FastField
                        type="checkbox"
                        as={Checkbox}
                        name="disableIndividualReport"
                      />
                    }
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                component={Grid}
                container
                spacing={1}
                pt="8px"
                alignItems="center"
              >
                <Grid item xs={2}>
                  <Typography>{t("tool.clientForm.css", "CSS")}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Field
                    variant="outlined"
                    fullWidth
                    name="reportCssUrl"
                    as={TextField}
                    size="small"
                    disabled={!isSpecialReport}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {editMode
                ? t("tool.addEdit.save", "Save")
                : t("tool.addEdit.create", "Create")}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                history.push(CLIENTS_URL);
              }}
            >
              {t("tool.reportForm.close", "Close")}
            </Button>
          </Grid>
        </Grid>
        {error && (
          <Typography color="secondary">
            {error?.response?.data?.message ??
              t("tool.addEdit.unexpectedError", "Unexpected error occurred")}
          </Typography>
        )}
        {loading && <Loader />}
      </Grid>
    </Grid>
  );
}

function ClientForm({ client, onSubmit, error, loading, editMode }) {
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        name: "",
        cssLink: "",
        isSpecialReport: false,
        reportMinParticipants: 1,
        reportCssUrl: "",
        favIconUrl: "",
        disableIndividualReport: false,
        reportAttrFilters: false,
        startCockpitHeaderHtml: "",
        resultCockpitHeaderHtml: "",
        dashboardConfigSheetId: "",
        ...(client &&
          Object.keys(client).reduce(
            (acc, key) => ({
              ...acc,
              [key]: client[key],
            }),
            {}
          )),
      }}
      onSubmit={async values => {
        const res = await onSubmit(values);

        if (res) {
          history.push(`${CLIENTS_URL}/${res._id}`);
        }
      }}
    >
      {() => {
        return (
          <Form>
            <InnerForm
              client={client}
              error={error}
              loading={loading}
              editMode={editMode}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default ClientForm;
