import traffic_red from "../../../static/images/custom_traffic_red.svg";
import traffic_green from "../../../static/images/custom_traffic_green.svg";
import traffic_yellow from "../../../static/images/custom_traffic_yellow.svg";
import { TrafficLight, TrafficLightValues } from "./types";

export const TRAFFIC_LIGHTS: TrafficLightValues = {
  [TrafficLight.Red]: traffic_red,
  [TrafficLight.Green]: traffic_green,
  [TrafficLight.Yellow]: traffic_yellow,
};

export const BAR_COLORS: TrafficLightValues = {
  [TrafficLight.Red]: "#ed1a3b",
  [TrafficLight.Green]: "#79a943",
  [TrafficLight.Yellow]: "#fcaf16",
};

export const TRAFFIC_LIGHTS_TITLES: TrafficLightValues = {
  [TrafficLight.Red]: "unterdurch&shy;schnittlich",
  [TrafficLight.Green]: "überdurch&shy;schnittlich",
  [TrafficLight.Yellow]: "durch&shy;schnittlich",
};
