import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import {
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import { ENTRIES_URL, GROUP_URL } from "../constants";
import { useDelete, useGetList } from "../hooks";
import { formatDateTime } from "../utils";

function GroupList() {
  const history = useHistory();
  const { t } = useTranslation();
  const getGroups = useGetList(API_URLS.GROUPS);
  const deleteGroup = useDelete(API_URLS.GROUPS);
  const confirm = useConfirm();

  return (
    <Container maxWidth={false} className="list-page">
      {(getGroups.isLoading || deleteGroup.isLoading) && <Loader />}
      {getGroups.error && (
        <Alert severity="error">
          {t("tool.general.error.dataFetching", "Data fetching error.")}
        </Alert>
      )}
      <TableContainer component={Paper} className="table-container">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("tool.groupList.id", "Name")}</TableCell>
              <TableCell>{t("tool.groupList.testName", "Test")}</TableCell>
              <TableCell align="right">
                {t("tool.groupList.from", "From")}
              </TableCell>
              <TableCell align="right">
                {t("tool.groupList.to", "To")}
              </TableCell>
              <TableCell align="right">
                {t("tool.groupList.started", "Started")}
              </TableCell>
              <TableCell align="right">
                {t("tool.groupList.finished", "Finished")}
              </TableCell>
              <TableCell align="right">
                {t("tool.groupList.createdAt", "Created")}
              </TableCell>
              <TableCell align="right">
                {t("tool.groupList.actions", "Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getGroups?.data?.map(group => (
              <TableRow key={group._id}>
                <TableCell component="th" scope="row">
                  {group.name}
                </TableCell>
                <TableCell align="left">{group.testName}</TableCell>
                <TableCell align="right">
                  {group.startDate &&
                    new Date(group.startDate).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  {group.endDate &&
                    new Date(group.endDate).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  {group.startedTestFlowsCount}
                </TableCell>
                <TableCell align="right">
                  {group.completedTestFlowsCount}
                </TableCell>
                <TableCell align="right">
                  {formatDateTime(group.createdAt)}
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 180 }}>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => {
                      history.push(`${ENTRIES_URL}?groupId=${group._id}`);
                    }}
                    size="large"
                    title={t(
                      "tool.groupList.showGroupReplies",
                      "Show Group Replies"
                    )}
                  >
                    <ListIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => {
                      history.push(`${GROUP_URL}/${group._id}`);
                    }}
                    size="large"
                    title={t("tool.groupList.editGroup", "Edit Group")}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => {
                      confirm({
                        title: t(
                          "tool.groupList.confirmDeletionTitle",
                          "Remove Confirmation"
                        ),
                        description: t(
                          "tool.groupList.confirmDeletionDesc",
                          "Are you sure you want to delete {{name}} ?",
                          { name: group.name }
                        ),
                      })
                        .then(() => deleteGroup.mutateAsync(group._id))
                        .catch(() => {});
                    }}
                    size="large"
                    title={t("tool.groupList.deleteGroup", "Delete Group")}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default GroupList;
