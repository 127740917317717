import { HorizonSelectorProps } from "./types";
import Short from "./Short";
import Long from "./Long";
import { HORIZON_MODE } from "../../../constants";
import { HorizonMode } from "../../../../../../@types";

function HorizonSelector({ onModeChanged, mode }: HorizonSelectorProps) {
  return (
    <svg x={-229}>
      {mode === HORIZON_MODE.short ? (
        <Short
          onClick={() => onModeChanged(HORIZON_MODE.long as HorizonMode)}
        />
      ) : (
        <Long
          onClick={() => onModeChanged(HORIZON_MODE.short as HorizonMode)}
        />
      )}
    </svg>
  );
}
export default HorizonSelector;
