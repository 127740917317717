export const mergeEntries = (updatedEntry, entries) => {
  const entryPresent = entries.find(
    e =>
      e.testId === updatedEntry.testId && e.testSetId === updatedEntry.testSetId
  );
  let newEntries = [];
  if (entryPresent) {
    newEntries = entries.map(e => {
      if (
        e.testId === updatedEntry.testId &&
        e.testSetId === updatedEntry.testSetId
      ) {
        return updatedEntry;
      }
      return e;
    });
  } else {
    newEntries = [...entries, updatedEntry];
  }

  return newEntries;
};
