import React, { useEffect, useState } from "react";
import Attribute from "./Attribute";
import "./styles.scss";
import { parseAttributeValue } from "./utils";

function AttributesPicker({
  availableAttributes,
  publicTest,
  onChangeHandler,
  isSpecialAttributes = false,
}) {
  const [currentAttributes, setCurrentAttributes] = useState({});
  const [hiddenAttributes, setHiddenAttributes] = useState({});
  const updateAttributes = attributes => {
    setCurrentAttributes(attributes);
    onChangeHandler(attributes);
  };

  useEffect(() => {
    const preselectedAttrs = availableAttributes.reduce((acc, attr) => {
      const values = publicTest[`${attr}Value`];
      if (values.length === 1) {
        acc[attr] = parseAttributeValue(values[0]).value;
      }

      return acc;
    }, {});

    setHiddenAttributes(preselectedAttrs);
    updateAttributes(preselectedAttrs);
  }, [publicTest, availableAttributes]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = attributes => {
    updateAttributes({ ...currentAttributes, ...attributes });
  };

  return (
    <div className="tg_attributes-picker">
      {availableAttributes.map((attr, i) => {
        return (
          <Attribute
            key={attr}
            index={i + 1}
            attribute={attr}
            onChange={onChange}
            publicTest={publicTest}
            currentAttributes={currentAttributes}
            isSpecialAttributes={isSpecialAttributes}
            isHidden={!!hiddenAttributes[attr]}
          />
        );
      })}
    </div>
  );
}

export default AttributesPicker;
